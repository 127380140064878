/*
 * @Description:
 * @Autor: liuguangkuo
 * @Date: 2020-06-30 13:34:12
 * @LastEditTime: 2021-01-18 11:14:51
 */

/**
 * 新人体验营
 */
let routes = [
  //新人体验营首页
  {
    path: "/newuser",
    name: "newuser",
    component: require("@/views/newuser/index").default,
    meta: {
      title: "新人体验营"
    }
  },
  //新人体验营首页
  {
    path: "/newuserSuccess",
    name: "newuserSuccess",
    component: require("@/views/newuser/newuserSuccess").default,
    meta: {
      title: ""
    }
  },
  //如何操作
  {
    path: "/newuserHelp",
    name: "newuserHelp",
    component: require("@/views/newuser/newuserHelp").default,
    meta: {
      title: "入群如何操作"
    }
  }
];
export default routes;
