
import Vue from "vue";
import { slider, slideritem } from "vue-concise-slider";
import { Toast, Lazyload, Popup } from "vant";
Vue.use(Toast)
  .use(Lazyload)
  .use(Popup);
export default Vue.extend({
  data() {
    return {
      swiperIndex: 0,
      w: 0,
      swiperOption: {
        // 轮播配置
        //自动轮播
        autoplay: true,
        on: {
          slideChange: val => {
            (this as any).swiperIndex = val.activeIndex;
          }
        }
      },
      info: {
        h5PageCxt: "",
        targetDays: "",
        curDayFinishFlg: "",
        joinDays: "",
        aliUpFlg: "",
        actImglnk: "",
        actImgUrl: "",
        lstBanners: [],
        orderType: "",
        qiniuToken: ""
      },
      options: {
        loop: true,
        thresholdDistance: "50",
        autoplay: "3000"
      }
    };
  },
  components: {
    slider,
    slideritem
  },
  created() {
    this.getPageInfo();
  },
  methods: {
    /**
     * 获取基础数据
     */
    getPageInfo() {
      const _this = this;
      (_this as any).renewFlg = _this.$route.query.renew ? true : false; // 续费状态
      let trainid = _this.$route.query.trainid; //活动id
      _this.$axios
        .post(
        /*  "/wuAPI/fitDcApi/fitH5Act/getAct0BuyFinshStackResource", */
        "/zhangAPI/SFitWeb/sfit/getAct0BuyFinshStackResource", 
          _this.$qs.stringify({
            "form.reqUid": _this.$F.userid() == 1 ? "10092" : _this.$F.userid(),
            "form.mbType": _this.$B.system() == "ios" ? "1" : "0"
          })
        )
        .then(res => {
          window.console.log(res);
          if (res.data.result == "Y") {
            this.info = res.data.entRet;
            this.w = (parseInt(res.data.entRet.targetDays) / 300) * 100;
          } else {
            Toast("页面加载失败");
          }
        })
        .catch(res => {
          Toast("页面加载失败");
        });
    },
    /**
     * 跳转提现页面
     */
    goMoney() {
      this.$router.push({
        path:
          "/vip/money?orderType=" +
          this.info.orderType +
          "&qiniuToken=" +
          this.info.qiniuToken
      });
    },
    /**
     * 跳转页面
     */
    goPage(index) {
      const arr: any = this.info.lstBanners[index];
      if (arr.disType == 3) {
        window.location.href = arr.imgLnk;
      } else {
        if (this.$B.system() == "ios") {
          this.$openPage.NativeOpenPage("0", JSON.parse(arr.stIosJson));
        } else {
          this.$openPage.NativeOpenPage("0", JSON.parse(arr.stAndJson));
        }
      }
    }
  }
});
