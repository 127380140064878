
import Vue from "vue";
import {
  Checkbox,
  CheckboxGroup,
  RadioGroup,
  Radio,
  Toast,
  Form,
  Field
} from "vant";
Vue.use(Checkbox)
  .use(CheckboxGroup)
  .use(RadioGroup)
  .use(Radio)
  .use(Field)
  .use(Form)
  .use(Toast);
export default Vue.extend({
  data() {
    return {
      info: {},
      btnShowFlg: true
    };
  },
  created() {
    const _this = this;
    const userid = _this.$route.query.userid;
    if (userid) {
      _this.btnShowFlg = false;
    }
    _this.$axios
      .get("/wuAPI/fitDcApi/fitH5Act/getQuestion", {
        params: {
          "form.planId": _this.$route.query.planid,
          "form.userId": userid ? userid : this.$F.userid()
        }
      })
      .then(res => {
        _this.info = res.data.entRet;
      })
      .catch(err => {
        Toast("页面加载失败");
      });
  },
  methods: {
    up(val) {
      const _this = this;
      _this.$axios
        .post(
          "/wuAPI/fitDcApi/fitH5Act/addQuestion",
          _this.$qs.stringify({
            "form.planId": _this.$route.query.planid,
            "form.userId": _this.$route.query.userid
              ? _this.$route.query.userid
              : this.$F.userid(),
            "form.fbJson": JSON.stringify(_this.info)
          })
        )
        .then(res => {
          if (res.data.result == "Y") {
            Toast("提交成功");
            this.btnShowFlg = false;
          } else {
            Toast("提交失败");
          }
        })
        .catch(err => {
          Toast("提交失败");
        });
      window.console.log(this.info);
    }
  }
});
