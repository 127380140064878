
import Vue from "vue";
import { Swipe, SwipeItem, Toast } from "vant";
import wxShare from "../../tool/wechat";
import countTo from "vue-count-to";
import { set } from "vue/types/umd";
Vue.use(Swipe)
  .use(SwipeItem)
  .use(Toast);
export default Vue.extend({
  components: { countTo },
  mixins: [wxShare],
  data() {
    return {
      info: {
        regYear: 0,
        regMonth: 0,
        regDay: 0,
        sptTime: 0,
        freqSTime: "00:00",
        freqETime: "00:00",
        longMon: 0,
        longDay: 0,
        longestTime: 0,
        largestMonth: 0,
        largestNum: 0,
        largestTime: 0,
        longestFrame: 0,
        mostParts: "",
        type: 0,
        useFlg: 0,
        viewImg: "", //好运签图片
        shareImg: "" //分享好运签
      }, //初始数据
      animatedClass: false,
      nextShow: false, //翻页提示显示状态
      startShow: true, //首页开始显示状态
      swiperShow: false, //swipe
      loadFlg: false, //加载
      flag: [
        {
          name: "Flag毁灭者",
          type: 0
        },
        {
          name: "全能达人",
          type: 0
        },
        {
          name: "腹肌发烧友",
          type: 0
        },
        {
          name: "胸肌有成",
          type: 0
        },
        {
          name: "多吃不胖",
          type: 0
        },
        {
          name: "早睡冠军",
          type: 0
        },
        {
          name: "和最爱的人一起过年",
          type: 0
        },
        {
          name: "年终奖满溢",
          type: 0
        }
      ],
      flagNum: 0, //选择心愿数量
      flagText: "", //选择心愿数据
      hongbaoShowFlg: 1, //显示情况
      shareShow: false //分享
    };
  },
  created() {
    /**
     * 设置微信分享
     */
    if (this.$B.browser() == "wechat") {
      this.setShare();
      return false;
    }
    let _this = this;
    _this.getPageInfo();
    /**
     * 右上角分享初始化
     */
    _this.$bridge.setShareInfo({
      title: "“牛”转好运🧧领开门红好礼",
      link: this.$F.getOrigin() + "/subject/2021-2-7",
      imgUrl: "https://cdn.fitapp.cn/vue/subject/2021-2-7/share.jpg",
      desc: "查收你的运动回忆，生成专属新春好运",
      ctitle: "“牛”转好运🧧领开门红好礼",
      olapflg: "25",
      display: "1",
      shareList: "1,2"
    });
    /**
     * 页面加载设置
     */
    _this.$bridge.setBridgeIsShowInfo({
      close: "1",
      pull: "0",
      prev: "1",
      noback: "0",
      slip: "1",
      service: "0"
    });
    /**接受客户端数据 */
    (window as any).sendWebMessage = function(val) {
      window.console.log(val);
    };
    Toast("活动已结束");
  },
  methods: {
    /**
     * 获取基础数据
     */
    getPageInfo() {
      const _this = this;
      _this.$axios
        .post(
          "/wuAPI/fitDcApi/fitH5Act/getUserAnnualSummary",
          _this.$qs.stringify({
            "form.reqUid": "64789-vue",
            "form.userId": _this.$F.userid() == 1 ? "10092" : _this.$F.userid(),
            "form.channel": "dc2"
          })
        )
        .then(res => {
          if (res.data.result == "Y") {
            let entRet = res.data.entRet;
            _this.info = entRet;
            _this.swiperShow = true;
            // if(res.data.entRet.useFlg == 1){
            //     this.startShow = false;
            //     this.swiperShow = false;
            //     this.hongbaoShowFlg = 2;
            // }
          } else {
            Toast("页面加载失败");
          }
        })
        .catch(res => {
          Toast("页面加载失败");
        });
    },
    /**
     * 微信分享
     */
    setShare() {
      const shareInfo = {
        title: `“牛”转好运🧧领开门红好礼`,
        desc: `查收你的运动回忆，生成专属新春好运`,
        link: this.$F.getOrigin() + "/subject/2021-2-7",
        img: "https://cdn.fitapp.cn/vue/subject/2021-2-7/share.jpg"
      };
      (this as any).wechatShare(shareInfo);
    },
    /**
     * 去生成好运签
     */
    goFlag() {
      this.swiperShow = false;
    },
    /**
     * 拆红包
     */
    goGift() {
      (window as any).gio("track", "web_bnt_click", {
        target_module: "马上拆红包",
        target_title: "2021，“牛”转好运",
        target_id: this.$F.userid()
      });
      //   window.location.href = "/subject/2021-2-7-gift";
      this.$router.push("/subject/2021-2-7-gift");
    },
    /**
     * 微信打开活动页面openFit
     */
    goFit() {
      this.$openPage.openFit("1", {
        url: this.$F.getOrigin() + "/subject/2021-2-7"
      });
    },
    /**
     * 开始
     */
    startFn() {
      Toast("活动已结束");
      return false;
      //   if (this.$B.browser() == "wechat") {
      //     this.goFit();
      //     return false;
      //   }
      //   this.swiperShow = true;
      //   this.startShow = false;
      //   this.nextShow = true;
      //   (this as any).$refs.example1.start();
      //   (this as any).$refs.example2.start();
      //   (this as any).$refs.example3.start();
      //   this.addAnimated();
      //   (window as any).gio("track", "web_bnt_click", {
      //     target_module: "开启我的运动回忆按钮",
      //     target_title: "2021，“牛”转好运",
      //     target_id: this.$F.userid()
      //   });
    },
    /**
     * 添加动画
     */
    addAnimated() {
      let _this = this;
      let obj = document.querySelectorAll(".animatedObj");
      for (let i = 0; i < obj.length; i++) {
        obj[i].classList.add("animated", "fadeInUp");
      }
      setTimeout(function() {
        _this.removeAnimated();
      }, 1000);
    },
    removeAnimated() {
      let obj = document.querySelectorAll(".animatedObj");
      for (let i = 0; i < obj.length; i++) {
        obj[i].classList.remove("animated", "fadeInUp");
      }
    },
    onChange(index) {
      this.removeAnimated();
      this.addAnimated();
      if (this.info.type == 1) {
        switch (index) {
          case 0:
            (this as any).$refs.example1.start();
            (this as any).$refs.example2.start();
            (this as any).$refs.example3.start();
            break;
          case 1:
            (this as any).$refs.example4.start();
            break;
          case 2:
            (this as any).$refs.example5.start();
            (this as any).$refs.example6.start();
            (this as any).$refs.example7.start();
            break;
          case 3:
            (this as any).$refs.example8.start();
            (this as any).$refs.example9.start();
            (this as any).$refs.example10.start();
            break;
          case 4:
            (this as any).$refs.example11.start();
            break;
          case 5:
            (this as any).$refs.example12.start();
            (this as any).$refs.example13.start();
            break;
          case 6:
            (this as any).$refs.example14.start();
            (this as any).$refs.example15.start();
            break;
          case 7:
            (this as any).$refs.example16.start();
            break;
        }
        if (index == 8) {
          this.nextShow = false;
        } else {
          this.nextShow = true;
        }
      } else {
        switch (index) {
          case 0:
            (this as any).$refs.example1.start();
            (this as any).$refs.example2.start();
            (this as any).$refs.example3.start();
            break;
          case 1:
            (this as any).$refs.example14.start();
            (this as any).$refs.example15.start();
            break;
          case 2:
            (this as any).$refs.example16.start();
            break;
        }
        if (index == 3) {
          this.nextShow = false;
        } else {
          this.nextShow = true;
        }
      }
    },
    /**
     * 选择心愿
     */
    selectFlag(index) {
      let type = this.flag[index].type;
      let num = this.flagNum;
      if (type == 0) {
        if (num > 2) {
          return false;
        }
        this.flagNum = num + 1;
      } else {
        this.flagNum = num - 1;
      }
      this.flag[index].type = type == 0 ? 1 : 0;
    },
    /**
     * 生成图片
     */
    creatImg() {
      this.loadFlg = true;
      let text = "";
      this.flag.forEach((element, index) => {
        if (element.type == 1) {
          text += index + 1 + ",";
        }
      });
      text = text.substring(0, text.length - 1);
      //   this.flagText = text.substring(0, text.length - 1);
      this.upCreatImgFn(text);
      //   this.hongbaoShowFlg = 2;
    },
    /**
     * 生成图片接口
     */
    upCreatImgFn(text) {
      const _this = this;
      _this.$axios
        .post(
          "/wuAPI/fitDcApi/fitH5Act/getUserAnnualPoster",
          _this.$qs.stringify({
            "form.reqUid": "64789-vue",
            "form.userId": _this.$F.userid() == 1 ? "10092" : _this.$F.userid(),
            "form.channel": "dc2",
            "form.sex": _this.$F.sex(),
            "form.context": text
          })
        )
        .then(res => {
          if (res.data.result == "Y") {
            this.info.viewImg = res.data.entRet.viewImg;
            this.info.shareImg = res.data.entRet.shareImg;
            this.loadFlg = false;
            this.hongbaoShowFlg = 2;
          } else {
            Toast("生成好运签失败");
          }
        })
        .catch(res => {
          Toast("页面加载失败");
        });
      (window as any).gio("track", "web_bnt_click", {
        target_module: "生成我的2021好运签",
        target_title: "2021，“牛”转好运",
        target_id: this.$F.userid()
      });
    },
    /**
     * 重新许愿
     */
    resetImg() {
      this.flag.forEach((element, index) => {
        this.flag[index].type = 0;
      });
      this.hongbaoShowFlg = 1;
      this.flagNum = 0;
      this.flagText = "";
    },
    /**
     * 分享到
     */
    share(type) {
      const _this = this;
      const shareInfo = {
        type: type,
        title: "“牛”转好运🧧领开门红好礼", //分享标题
        imgUrl: _this.info.shareImg, //分享图片
        imgActUrl: _this.info.shareImg, //分享图片
        desc: "查收你的运动回忆，生成专属新春好运", //分享描述
        ctitle: "“牛”转好运🧧领开门红好礼",
        olapflg: "22",
        isphoto: "1"
      };

      _this.$bridge.NativeBridge("webViewShare", shareInfo);
      setTimeout(function() {
        if (_this.info.useFlg == 0) {
          _this.hongbaoShowFlg = 3;
        }
        _this.shareShow = false;
      }, 3000);
      (window as any).gio("track", "web_bnt_click", {
        target_module: type == 0 ? "朋友圈分享" : "微信好友分享",
        target_title: "2021，“牛”转好运",
        target_id: this.$F.userid()
      });
    }
  }
});
