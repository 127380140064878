
import Vue from "vue";
import { slider, slideritem } from "vue-concise-slider";
import { Toast, Lazyload, Popup } from "vant";
Vue.use(Toast)
  .use(Lazyload)
  .use(Popup);
let timer: any;
let timerS: any;
let aliId = "";
let isClick = true;
export default Vue.extend({
  data() {
    return {
      tabIndex: 0,
      tel: "", //电话
      code: "", //验证码
      endTime: 60, //倒计时
      codeText: "获取验证码",
      popFlg: false,
      popTitle: "",
      popText: "",
      btnFlg: true,
      someList: [
        "https://cdn.fitapp.cn/vue/ali/vip/common1.png",
        "https://cdn.fitapp.cn/vue/ali/vip/common2.png",
        "https://cdn.fitapp.cn/vue/ali/vip/common3.png",
        "https://cdn.fitapp.cn/vue/ali/vip/common4.png",
        "https://cdn.fitapp.cn/vue/ali/vip/common5.png",
        "https://cdn.fitapp.cn/vue/ali/vip/common6.png",
        "https://cdn.fitapp.cn/vue/ali/vip/common7.png"
      ],
      options: {
        itemAnimation: true,
        centeredSlides: true,
        loopedSlides: 2,
        slidesToScroll: 1
      }
    };
  },
  created() {
    this.tabSwiper();
  },
  beforeCreate() {
    document.title = "7天会员免费领";
  },
  beforeMount() {
    const s = document.createElement("script");
    s.src = "https://appx/web-view.min.js";
    document.body.appendChild(s);
  },
  mounted() {
    setTimeout(function() {
      (window as any).my.onMessage = function(e) {
        window.console.log(e, e.res.data);
        let json = JSON.parse(e.res.data);
        aliId = json.senduserId;
      };
      (window as any).my.postMessage({ sendAli: "getUserid" });
    }, 5000);
  },
  components: {
    slider,
    slideritem
  },
  methods: {
    /**
     * tab
     */
    tabFn(index) {
      clearInterval(timer);
      this.tabIndex = index;
      const tabObj: any = document.querySelectorAll(".tab-item");
      for (let i = 0; i < tabObj.length; i++) {
        tabObj[i].style = "z-index:0";
      }
      tabObj[index].style = "z-index:1";
      this.tabSwiper();
    },
    /**
     * 自动滑动
     */
    tabSwiper() {
      const _this = this;
      timer = setInterval(function() {
        _this.tabIndex = _this.tabIndex + 1;
        if (_this.tabIndex == 3) {
          _this.tabIndex = 0;
        }
        const tabObj: any = document.querySelectorAll(".tab-item");
        for (let i = 0; i < tabObj.length; i++) {
          tabObj[i].style = "z-index:0";
        }
        tabObj[_this.tabIndex].style = "z-index:1";
      }, 3000);
    },
    /**
     * 获取验证码
     */
    getCode() {
      const _this = this;
      if (isClick) {
        isClick = false;
        if (!_this.tel) {
          isClick = true;
          Toast("请填写手机号");
          return false;
        } else if (!/^1[23456789]\d{9}$/.test(_this.tel)) {
          isClick = true;
          Toast("请填写正确的手机号");
          return false;
        }
        _this.$axios
          .post(
            "/zhangAPI/SFitWeb/sfit/getH5Verification",
            _this.$qs.stringify({
              "form.userId": _this.tel,
              "form.version": 4.0,
              "form.phoneNumber": _this.tel
            })
          )
          .then(res => {
            window.console.log(res);
            if (res.data.result == "Y") {
              Toast("短信发送成功");
              this.countDown();
            } else {
              Toast(res.data.message);
            }
            isClick = true;
          })
          .catch(res => {
            Toast("短信发送失败");
            isClick = true;
          });
      }
    },
    /**
     * 提交电话
     */
    upTel() {
      const _this = this;
      if (isClick) {
        isClick = false;
        if (!_this.tel) {
          Toast("请填写手机号");
          isClick = true;
          return false;
        }
        if (!/^1[23456789]\d{9}$/.test(_this.tel)) {
          Toast("请填写正确的手机号");
          isClick = true;
          return false;
        }
        if (!_this.code) {
          Toast("请填写验证码");
          isClick = true;
          return false;
        }
        _this.$axios
          .post(
            "/zhangAPI/SFitWeb/sfitactivity/addFitPr",
            _this.$qs.stringify({
              "form.Flag": 4,
              "form.phoneNumber": _this.tel,
              "form.verification": _this.code,
              "form.aliId": aliId
            })
          )
          .then(res => {
            if (res.data.result == "Y") {
              _this.popFlg = true;
              _this.popTitle = res.data.popTitle;
              _this.popText = res.data.popText;
              _this.btnFlg = false;
            } else {
              if (res.data.message == "-4") {
                Toast("验证码错误");
              } else {
                Toast("提交失败");
              }
            }
            isClick = true;
          })
          .catch(res => {
            Toast("提交失败");
            isClick = true;
          });
      }
    },
    countDown() {
      const _this = this;
      timerS = setInterval(function() {
        _this.endTime--;
        _this.codeText = _this.endTime + "s重新获取";
        if (_this.endTime == 0) {
          _this.codeText = "重新获取";
          _this.endTime = 60;
          clearInterval(timerS);
        }
      }, 1000);
    }
  }
});
