
import Vue from "vue";
import {
  Toast,
  Icon,
  Popup,
  DatetimePicker,
  Tab,
  Tabs,
  Search,
  Dialog
} from "vant";
import dayjs from "dayjs";
Vue.use(DatetimePicker)
  .use(Toast)
  .use(Icon)
  .use(Popup)
  .use(Tab)
  .use(Tabs)
  .use(Search)
  .use(Dialog);
export default Vue.extend({
  data() {
    return {
      strengNm: "请选择课程类型", //选中课程类型
      strengFlg: "", //选中课程id
      planId: "", //课ID
      planNm: "请选择课程", //课程名称
      formalType: "",
      value: "",
      lstStrengthVal: [], //课程类型
      lstStrengthId: [], //课程类型id
      lstStrengthIndex: 0, //强度默认索引
      showStrength: false, //课程类型
      lstCoachIndex: 0, //强度默认索引
      changeFlg: false, //修改课程
      searchFlg: false, //搜索
      isSearchFlg: false, //当前是否搜索过
      searchHistory: [], //搜索历史
      searchHistoryFlg: false, //历史记录状态
      planList: {
        courseNum: "",
        lstDefault: [],
        lstDegree: [],
        lstVipPlan: []
      },
      searchData: {
        //搜索数据
        courseNum: "",
        lstDefault: [],
        lstDegree: [],
        lstVipPlan: []
      },
      selectPlanId: "", //选中的课程id
      selectPlanName: "" //选中的课程
    };
  },
  created() {
    const _this = this;
    _this.getPlanType();
    _this.getHistory();
    /**接受客户端数据 */
    (window as any).sendWebMessage = function(val) {
      let obj = JSON.parse(val);
      if (obj.event == "click" && obj.name == "back") {
        _this.back();
      }
    };
  },
  methods: {
    /**
     * 获取课程类型
     */
    getPlanType() {
      let _this = this;
      _this.$axios
        .post(
          "/wuAPI/vipDcApi/cusPlanH5/getPlanFormatType",
          _this.$qs.stringify({
            "form.reqUid": "64789-vue",
            "form.userId": _this.$F.userid(),
            "form.channel": "dc2"
          })
        )
        .then(res => {
          if (res.data.entRet.length > 0) {
            res.data.entRet.forEach(item => {
              (this as any).lstStrengthVal.push(item.d_name);
              (this as any).lstStrengthId.push(item.d_code);
            });
          }
        })
        .catch(res => {
          Toast("页面加载失败");
        });
    },
    //打开课程
    showPlan() {
      if (!this.strengFlg) {
        Toast("请先选择课程类型");
        return false;
      }
      this.getPlanList(this.strengFlg);
      this.changeFlg = true;
    },
    onSearch(val) {
      let searchHistoryArr: any =
        JSON.parse((localStorage as any).getItem("searchHistory")) != null
          ? JSON.parse((localStorage as any).getItem("searchHistory"))
          : [];
      searchHistoryArr.push(val);
      searchHistoryArr = [...new Set(searchHistoryArr)];
      localStorage.setItem("searchHistory", JSON.stringify(searchHistoryArr));
      this.value = "";
      this.searchHistory = searchHistoryArr;
      this.isSearchFlg = true;
      this.getPlanList(this.strengFlg, val, "99", true);
    },
    /**
     * 获取搜索历史
     */
    getHistory() {
      let searchHistoryArr: any = (localStorage as any).getItem("searchHistory")
        ? JSON.parse((localStorage as any).getItem("searchHistory"))
        : [];
      this.searchHistory = searchHistoryArr;
    },
    /**
     * 返回页面
     */
    back() {
      this.$bridge.NativeBridge("blackAction", {});
    },
    /**
     * 清楚历史
     */
    clearHistory(index) {
      if (index == "all") {
        localStorage.removeItem("searchHistory");
        this.searchHistory = [];
      } else {
        let searchHistoryArr: any = JSON.parse(
          (localStorage as any).getItem("searchHistory")
        );
        searchHistoryArr.splice(index, 1);
        (localStorage as any).setItem(
          "searchHistory",
          JSON.stringify(searchHistoryArr)
        );
        this.searchHistory = searchHistoryArr;
      }
      this.searchHistoryFlg = false;
    },
    onSearchCancel() {
      this.searchFlg = false;
    },
    onCancelStrength() {
      this.showStrength = false;
    },
    /**
     * 选择强度
     */
    onConfirmStrength() {
      const index =
        parseInt((this as any).$refs.strengthPicker.getIndexes().toString()) +
        1;
      this.showStrength = false;
      this.strengNm = this.lstStrengthVal[index - 1];
      this.strengFlg = this.lstStrengthId[index - 1];
      this.planId = "";
      this.planNm = this.planNm == "请选择课程" ? "请选择课程" : "";
    },
    /**
     * 获取课程列表
     */
    getPlanList(formalType, title?, cusDegree = "99", isSearch?) {
      let _this = this;
      _this.$axios
        .post(
          "/wuAPI/vipDcApi/cusPlanH5/getVipCourse",
          _this.$qs.stringify({
            "form.reqUid": "64789-vue",
            "form.userId": _this.$F.userid(),
            "form.channel": "dc2",
            "form.formalType": formalType,
            "form.title": title,
            "form.cusDegree": cusDegree,
            "form.searchFlg": title ? "1" : "0"
          })
        )
        .then(res => {
          window.console.log(res);
          const json = res.data;
          if (isSearch) {
            _this.searchData = json.entRet;
            this.searchHistoryFlg = false;
          } else {
            _this.planList = json.entRet;
          }
        })
        .catch(res => {
          Toast("页面加载失败");
        });
    },
    /**
     * 选中课程
     */
    selectPlanFn(courseId, courseNm) {
      this.planId = courseId;
      this.planNm = courseNm;
    },
    /**
     * 修改课程
     */
    changePlanFn() {
      this.changeFlg = false;
      this.searchFlg = false;
      this.searchData.lstVipPlan = [];
      this.isSearchFlg = false;
    },
    /**
     * 显示历史记录
     */
    onFocus() {
      //searchHistory: [], //搜索历史
      //searchHistoryFlg:false, //历史记录状态
      if (this.searchHistory.length > 0) {
        this.searchHistoryFlg = true;
      }
    },
    upPlanData() {
      let _this = this;
      if (!_this.planId) {
        Toast("请选择课程");
        return false;
      }
      let userid = this.$route.query.userid;
      let planid = this.$route.query.planid;
      let noweek = this.$route.query.noweek;
      _this.$axios
        .post(
          "/wuAPI/vipDcApi/cusPlanH5/addCusPlanCourse",
          _this.$qs.stringify({
            "form.reqUid": "64789-vue",
            "form.userId": userid,
            "form.channel": "dc2",
            "form.cusPlanid": planid,
            "form.cusWeekid": noweek,
            "form.planId": _this.planId,
            "form.lblType": _this.strengFlg
          })
        )
        .then(res => {
          const json = res.data;
          if (json.result == "Y") {
            Toast("添加成功");
            setTimeout(function() {
              _this.back();
            }, 2000);
          } else {
            Toast(json.entError.errorMessage);
          }
        })
        .catch(res => {
          Toast("页面加载失败");
        });
    }
  }
});
