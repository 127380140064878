/*
 * @Description:
 * @Autor: liuguangkuo
 * @Date: 2019-12-11 17:31:42
 * @LastEditTime: 2019-12-30 16:22:05
 */
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {}
});
