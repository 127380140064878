/*
 * @Description:
 * @Autor: liuguangkuo
 * @Date: 2020-02-28 13:23:07
 * @LastEditTime: 2022-03-04 11:02:47
 */
/**
 * 专题活动路由文件
 */
let routes = [
  //显示直播列表活动页面、长时间使用
  {
    path: "/subject/live",
    name: "subjectLive",
    component: require("@/views/subject/live").default,
    meta: {
      title: "Fit TV直播课表",
    },
  },
  //5周年活动 2020.4.13-2020.4.15、已结束
  {
    path: "/subject/5year",
    name: "subject5year",
    component: require("@/views/subject/5year").default,
    meta: {
      title: "Fit 5周年庆",
    },
  },
  //推送落地页
  {
    path: "/subject/push",
    name: "subjectPush",
    component: require("@/views/subject/push").default,
    meta: {
      title: "通知",
    },
  },
  //环信客服
  {
    path: "/subject/huanxin",
    name: "subjectHuanxin",
    component: require("@/views/subject/huanxin").default,
    meta: {
      title: "环信客服",
    },
  },
  // 问卷调查
  {
    path: "/subject/questionnaire",
    name: "subjectQuestionnaire",
    component: require("@/views/subject/questionnaire").default,
    meta: {
      title: "回访调查问卷",
    },
  },
  // 8.3-8.31日活动、已结束
  {
    path: "/subject/vipMall8-3",
    name: "subjectVip8-3",
    component: require("@/views/subject/vip8-3").default,
    meta: {
      title: "Fit全民健身月",
    },
  },
  // 8.3-8.31日活动、已结束
  {
    path: "/subject/vipCode8-3",
    name: "subjectVipCode8-3",
    component: require("@/views/subject/vipCode8-3").default,
    meta: {
      title: "Fit全民健身月",
    },
  },
  // 8.3-8.31日活动、已结束
  {
    path: "/subject/vipCode8-24",
    name: "subjectVipCode8-24",
    component: require("@/views/subject/vipCode8-24").default,
    meta: {
      title: "Fit全民健身月",
    },
  },
  // 10月国庆活动、已结束
  {
    path: "/subject/vipMall10-1",
    name: "subjectVip10-1",
    component: require("@/views/subject/vip10-1").default,
    meta: {
      title: "国庆VIP年会员买1送1",
    },
  },
  //兑吧重定向
  {
    path: "/subject/duibaRedirect",
    name: "subjectDuibaRedirect",
    component: require("@/views/subject/duibaRedirect").default,
    meta: {
      title: "兑吧",
    },
  },
  //2020双11
  {
    path: "/subject/2020-11-11",
    name: "2020-11-11",
    component: require("@/views/subject/2020-11-11").default,
    meta: {
      title: "11.11 重燃开练",
    },
  },
  {
    path: "/subject/invite2020-11-11",
    name: "invite2020-11-11",
    component: require("@/views/subject/invite2020-11-11").default,
    meta: {
      title: "助力免费送VIP会员",
    },
  },
  {
    path: "/subject/get2020-11-11",
    name: "get2020-11-11",
    component: require("@/views/subject/get2020-11-11").default,
    meta: {
      title: "权益领取",
    },
  },
  /*超级私教*/
  {
    path: "/subject/fitvipDw",
    name: "fitvipDw",
    component: require("@/views/subject/fitvipDw").default,
    meta: {
      title: "超级私教",
    },
  },
  //2020双12
  {
    path: "/subject/2020-12-12",
    name: "2020-12-12",
    component: require("@/views/subject/2020-12-12").default,
    meta: {
      title: "Fit VIP年会员大促",
    },
  },
  //2021元旦
  {
    path: "/subject/2021-1-1",
    name: "2021-1-1",
    component: require("@/views/subject/2021-1-1").default,
    meta: {
      title: "Fit 新年大促",
    },
  },
  //2021元旦
  {
    path: "/subject/2021-1-1-code",
    name: "2021-1-1-code",
    component: require("@/views/subject/2021-1-1-code").default,
    meta: {
      title: "新年福利兑换",
    },
  },
  //2021元旦
  {
    path: "/subject/2021-1-5",
    name: "2021-1-5",
    component: require("@/views/subject/2021-1-5").default,
    meta: {
      title: "VIP年会员新年福利购",
    },
  },
  //2021元旦
  {
    path: "/subject/2021-1-5-code",
    name: "2021-1-5-code",
    component: require("@/views/subject/2021-1-5-code").default,
    meta: {
      title: "暖冬福利兑换",
    },
  },
  //能量换F币
  {
    path: "/subject/fitExchange",
    name: "fitExchange",
    component: require("@/views/subject/fitExchange").default,
    meta: {
      title: "能量换F币",
    },
  },
  //友邦超级私教
  {
    path: "/subject/2021-1-21",
    name: "2021-1-21",
    component: require("@/views/subject/2021-1-21").default,
    meta: {
      title: "超级私教",
    },
  },
  //2021新年活动
  {
    path: "/subject/2021-2-7",
    name: "2021-2-7",
    component: require("@/views/subject/2021-2-7").default,
    meta: {
      title: "2021，“牛”转好运",
    },
  },
  //2021新年礼包
  {
    path: "/subject/2021-2-7-gift",
    name: "2021-2-7-gift",
    component: require("@/views/subject/2021-2-7-gift").default,
    meta: {
      title: "Fit暖心大礼包",
    },
  },
  //2021-3-8
  {
    path: "/subject/2021-3-8",
    name: "2021-3-8",
    component: require("@/views/subject/2021-3-8").default,
    meta: {
      title: "动出女王每一面",
    },
  },
  //2021-3-8
  {
    path: "/subject/2021-4-9",
    name: "2021-4-9",
    component: require("@/views/subject/2021-4-9").default,
    meta: {
      title: "Fit 6周年庆活动",
    },
  },
  //2021-4-22
  {
    path: "/subject/2021-4-22",
    name: "2021-4-22",
    component: require("@/views/subject/2021-4-22").default,
    meta: {
      title: "Fit & 犀鸟学球联合会员",
    },
  },
  //2021-4-22
  {
    path: "/subject/2021-4-22-code",
    name: "2021-4-22-code",
    component: require("@/views/subject/2021-4-22-code").default,
    meta: {
      title: "Fit & 犀鸟学球联合会员",
    },
  },
  //2021-6-18
  {
    path: "/subject/2021-6-18",
    name: "2021-6-18",
    component: require("@/views/subject/2021-6-18").default,
    meta: {
      title: "Fit 618大促",
    },
  },
  //2021-6-18
  {
    path: "/subject/2021-8-4",
    name: "2021-8-4",
    component: require("@/views/subject/2021-8-4").default,
    meta: {
      title: "全民健身月",
    },
  },
  //2021-10-1
  {
    path: "/subject/2021-10-1",
    name: "2021-10-1",
    component: require("@/views/subject/2021-10-1").default,
    meta: {
      title: "国庆VIP年会员买1送1",
    },
  },
  //2021-10-1
  {
    path: "/subject/2021-11-11",
    name: "2021-11-11",
    component: require("@/views/subject/2021-11-11").default,
    meta: {
      title: "双11狂欢",
    },
  },
  //2021双12
  {
    path: "/subject/2021-12-12",
    name: "2021-12-12",
    component: require("@/views/subject/2021-12-12").default,
    meta: {
      title: "Fit VIP年会员大促",
    },
  },
  //2022元旦
  {
    path: "/subject/2022-1-1",
    name: "2022-1-1",
    component: require("@/views/subject/2022-1-1").default,
    meta: {
      title: "Fit 新年大促",
    },
  },

  //2022新年
  {
    path: "/subject/2022-1-30",
    name: "2022-1-30",
    component: require("@/views/subject/2022-1-30").default,
    meta: {
      title: "Fit 春节大促",
    },
  },

  //2022 3.8
  {
    path: "/subject/2022-3-8",
    name: "2022-3-8",
    component: require("@/views/subject/2022-3-8").default,
    meta: {
      title: "五一买一送一活动",
    },
  },
  {
    path: "/subject/topsports",
    name: "topsports",
    component: require("@/views/subject/topsports").default,
    meta: {
      title: "动起来 才有型",
    },
  },
];
export default routes;
