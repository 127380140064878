
import Vue from "vue";
import { Toast, Icon } from "vant";
Vue.use(Icon);
export default Vue.extend({
  data() {
    return {
      loop: undefined
    };
  },
  methods: {
    start() {
      clearTimeout(this.loop);
      (this as any).loop = setTimeout(() => {
        this.savePhoto();
      }, 500);
    },
    end() {
      clearTimeout(this.loop);
    },
    /**
     * 保存图片
     */
    savePhoto() {
      const _this = this;
      let obj = {
        url: "https://cdn.fitapp.cn/vue/cjsj/league/p9.jpg",
        text: "",
        isOpenWeixin: "0",
        successTipsText: "保存成功" //保存成功提示文案ios
      };
      this.$bridge.NativeBridge("savePhoto", obj);
    },
    /**
     * 打开小程序
     */
    goSuperCoach() {
      this.$openPage.NativeOpenPage(
        "100",
        "weixin://dl/business/?t=9OsVArj7g8s"
      );
    }
  }
});
