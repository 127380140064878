
import Vue from "vue";
import scroll from "vue-seamless-scroll";
import wxShare from "../../tool/wechat";
import cookie from "vue-cookies";
import { Toast, Lazyload, Popup } from "vant";
const iosInfo = `1. 活动时间：11月4日 - 11月16日。<br />
2. 活动期间，同一用户（同一用户名、设备、手机号都视为同一用户）仅限参与1次。<br />
3. 活动期间内用户需在活动页完成购买Fit VIP年会员①，系统将额外自动发放1年VIP会员有效期，完成条件①后，可在活动页中发起好友助力活动，满足3位好友助力，即可免费获得权益礼包，权益礼包领取方式届时会发至您的Fit账户中，请注意查收。<br />
4. 每个好友仅有1次为好友助力的机会，成功助力后，系统将自动发放VIP会员及F币（可兑换大师课堂）至微信授权所助力的Fit账号中，请注意查收。<br />
5. 本次活动重复购买将不附赠联合会员权益，权益数量有限。<br />
6. 所赠权益将通过消息提醒推送，请务必检查是否开启APP推送提醒，也可在消息栏中进行查看，如未收到，可联系在线客服咨询。<br />
7. 权益内容：超级训练营兑换有效期自领取后的60天内可以进行兑换使用，可兑换指定超级训练营 — 14天细腰马甲线冲刺计划。<br />
8. 成功购买后，实物商品如遇质量问题，可联系在线客服进行退换；7日内可无理由对商品进行退货，由于Fit VIP会员服务是数字化商品，基于互联网服务的特殊性和国家相关管理规定，如因您的个人原因发生错误购买或无法正常享受会员权益，Fit不能退还相应会员费。<br />
9. Fit保留本次活动在法律范围内的最终解释权。`;
const anInfo = `1. 活动时间：11月4日 - 11月16日。<br />
2. 活动期间，同一用户（同一用户名、设备、手机号都视为同一用户）仅限参与1次。<br />
3. 活动期间内用户需在活动页完成购买Fit VIP年会员①，系统将额外自动发放1年VIP会员有效期，完成条件①后，可在活动页中发起好友助力活动，每个好友仅有1次为好友打call的机会，满足3位好友打call，即可免费获得权益礼包，权益礼包领取方式届时会发至您的Fit账户中，请注意查收。<br />
4. 每个好友仅有1次为好友助力的机会，成功助力后，系统将自动发放VIP会员及F币（可兑换大师课堂）至微信授权所助力的Fit账号中，请注意查收。<br />
5. 本次活动重复购买将不附赠联合会员权益，权益数量有限。<br />
6. 所赠权益将通过消息提醒推送，请务必检查是否开启APP推送提醒，也可在消息栏中进行查看，如未收到，可联系在线客服咨询。<br />
7. 权益内容：超级训练营兑换有效期自领取后的60天内可以进行兑换使用，可兑换指定超级训练营 — 14天细腰马甲线冲刺计划。<br />
8. 成功购买后，由于Fit VIP会员服务是数字化商品，基于互联网服务的特殊性和国家相关管理规定，如因您的个人原因发生错误购买或无法正常享受会员权益，Fit不能退还相应会员费。<br />
9. Fit保留本次活动在法律范围内的最终解释权。`;
Vue.use(Toast)
  .use(Lazyload)
  .use(Popup);
export default Vue.extend({
  data() {
    return {
      browser: this.$B.browser(),
      shareFlg: false,
      show: false,
      shareImg: "", //海报
      infoText: "", //活动规则
      isInviteOver: 0,
      info: {
        actFlg: "0",
        userBuyStatus: "1",
        andComId: "",
        comdDesc: "",
        lstAct0GrpComData: [
          {
            imgBlnk: "",
            grpTitle: "",
            activityPrice: ""
          }
        ],
        lstInvUser: [{ headImg: "" }, { headImg: "" }, { headImg: "" }],
        entMeda: []
      },
      imgList: {
        img01: "https://cdn.fitapp.cn/vue/subject/11-11/pic_03.png",
        img02: "https://cdn.fitapp.cn/vue/subject/11-11/pic_04.png",
        img03: "https://cdn.fitapp.cn/vue/subject/11-11/pic_05.png",
        img04: "https://cdn.fitapp.cn/vue/subject/11-11/pic_06.png",
        img05: "https://cdn.fitapp.cn/vue/subject/11-11/pic_07.png",
        img06: "https://cdn.fitapp.cn/vue/subject/11-11/pic_08.png",
        img07: "https://cdn.fitapp.cn/vue/subject/11-11/pic_09.png",
        img08: "https://cdn.fitapp.cn/vue/subject/11-11/logo.png"
      }
    };
  },
  computed: {
    /**
     * 中奖信息滚动
     */
    optionSingleHeightTime() {
      return {
        singleHeight: 0.46,
        waitTime: 2500,
        openTouch: false,
        // switchSingleStep:100,
        isSingleRemUnit: true
      };
    }
  },
  created() {
    const _this = this;
    _this.getPageInfo();
    _this.infoText = _this.$B.system() == "ios" ? iosInfo : anInfo;
    /**接受客户端数据 */
    (window as any).sendWebMessage = function(val) {
      let obj = JSON.parse(val);
      if (obj.name == "openPage") {
        _this.info.userBuyStatus = "2";
        // window.location.href = "/subject/get2020-11-11";
      }
      if (obj.name == "savePhoto" && obj.json.type == "success") {
        _this.shareFlg = false;
      }
    };
    /**
     * 右上角分享初始化
     */
    _this.$bridge.setShareInfo({
      title: "11.11 重燃开练！Fit Vip会员买1年送1年",
      link: this.$F.getOrigin() + "/subject/2020-11-11",
      imgUrl: "https://cdn.fitapp.cn/vue/subject/11-11/share.png",
      desc: "好友助力另赠7大超值权益礼包",
      ctitle: "11.11 重燃开练！Fit Vip会员买1年送1年",
      olapflg: "25",
      display: "1",
      shareList: "1,2"
    });
  },
  methods: {
    /**
     * 获取基础数据
     */
    getPageInfo() {
      const _this = this;
      (_this as any).renewFlg = _this.$route.query.renew ? true : false; // 续费状态
      let trainid = _this.$route.query.trainid; //活动id
      _this.$axios
        .post(
          "/wuAPI/fitDcApi/fitH5Act2020/get20Double11ActHomeResource",
          _this.$qs.stringify({
            "form.reqUid": "64789-vue",
            "form.userId": _this.$F.userid() == 1 ? "10092" : _this.$F.userid(),
            "form.mbType": _this.$B.system() == "ios" ? "1" : "0",
            "form.channel": "dc2"
          })
        )
        .then(res => {
          window.console.log(res);
          if (res.data.result == "Y") {
            let entRet = res.data.entRet;
            _this.info = entRet;
            _this.shareImg = entRet.qrcdImgLnk;
            for (let i = 0; i < entRet.lstInvUser.length; i++) {
              if (entRet.lstInvUser[i].headImg) {
                _this.isInviteOver = i;
              }
            }
          } else {
            Toast("页面加载失败");
          }
        })
        .catch(res => {
          Toast("页面加载失败");
        });
    },
    /**
     * 打开课程详情
     */
    goTrain(id) {
      if (this.$B.browser() != "fit") {
        this.goFit();
        return false;
      }
      this.$openPage.NativeOpenPage("7", id);
    },
    /**
     * 打开课程库
     */
    goTrainList() {
      if (this.$B.browser() != "fit") {
        this.goFit();
        return false;
      }
      this.$openPage.NativeOpenPage("6", "8");
    },
    /**
     * 打开客服
     */
    openCustomService() {
      if (this.browser == "wechat") {
        window.location.href =
          "https://kefu.easemob.com/webim/im.html?configId=1e538b4b-0ba0-4a68-8273-f779dac84cda";
        return false;
      }
      this.$openPage.NativeOpenPage("3");
    },
    /**
     * 微信打开活动页面openFit
     */
    goFit() {
      window.console.log(this.$F.getOrigin() + "subject/2020-11-11", 31231);
      this.$openPage.openFit("1", {
        url: this.$F.getOrigin() + "/subject/2020-11-11"
      });
    },
    /**
     * 支付
     */
    payFn() {
      const _this = this;
      if (_this.browser == "wechat") {
        _this.goFit();
        return false;
      }
      if (_this.info.actFlg == "-1") {
        Toast("活动未开始");
        return false;
      }
      if (_this.info.actFlg == "-2") {
        Toast("活动已结束");
        return false;
      }
      if (_this.$F.dev() >= 620) {
        const popGoodsInfo = {
          commodityId: _this.info.andComId, //商品id
          callType: "5",
          commodityTitle: _this.info.lstAct0GrpComData[0].grpTitle, //商品标题
          commodityComment: _this.info.comdDesc, //商品描述
          comment: _this.info.comdDesc, //商品描述
          price: _this.info.lstAct0GrpComData[0].activityPrice, //价格
          commodityType: "3",
          buyComment: _this.info.comdDesc,
          buyTitle: _this.info.lstAct0GrpComData[0].grpTitle //商品标题
        };
        let payObj = {
          showApplePay: "0",
          goodsInfo: JSON.stringify(popGoodsInfo),
          cusColor: "#DBB76A",
          androidStyleType: "0",
          callback: "successFn",
          paySort: "0,1",
          showPaySheet: "0"
        };

        if (_this.$F.dev() >= 635 && _this.$B.system() == "ios") {
          payObj.showPaySheet = "0";
          payObj.showApplePay = "1";
          payObj.paySort = "";
        }
        //支付
        if (_this.$B.system() == "ios") {
          if (_this.$F.dev() >= "647") {
            _this.$openPage.NativeOpenPage(
              "8",
              _this.info.lstAct0GrpComData[0]
            );
          } else {
            _this.$openPage.NativeOpenPage(
              "5",
              _this.info.lstAct0GrpComData[0]
            );
          }
        } else {
          _this.$bridge.NativeBridge("payPop", payObj);
        }
        (window as any).successFn = function(msg) {
          msg = JSON.parse(msg);
          if (msg.type == "success") {
            _this.info.userBuyStatus = "2";
            _this.creatImg(1);
            if (_this.$B.system() != "ios") {
              Toast("支付成功");
            }
          }
        };
      } else {
        Toast("请下载最新版app！");
      }
      (window as any).gio("track", "web_bnt_click", {
        target_module: "立即开通",
        target_title: "双11活动主页面",
        target_id: _this.$F.userid()
      });
    },
    /**
     * 生成图片
     */
    creatImg(pay) {
      const _this = this;
      if (_this.info.actFlg == "-1") {
        Toast("活动未开始");
        return false;
      }
      if (_this.info.actFlg == "-2") {
        Toast("活动已结束");
        return false;
      }
      if (pay != 1) {
        _this.shareFlg = true;
        (window as any).gio("track", "web_bnt_click", {
          target_module: "邀请好友助力",
          target_title: "双11活动主页面",
          target_id: _this.$F.userid()
        });
      }
      if (_this.shareImg) {
        return false;
      }
      _this.$axios
        .post(
          "/wuAPI/fitDcApi/fitH5Act2020/get20Double11ActQrcdRes",
          _this.$qs.stringify({
            "form.reqUid": "64789-vue",
            "form.userId": _this.$F.userid() == 1 ? "10092" : _this.$F.userid(),
            "form.mbType": _this.$B.system() == "ios" ? "1" : "0",
            "form.channel": "dc2"
          })
        )
        .then(res => {
          if (res.data.result == "Y") {
            _this.shareImg = res.data.entRet.qrcdImgLnk;
          }
        })
        .catch(res => {
          Toast("页面加载失败");
        });
    },
    /**
     * 保存图片
     */
    savePhoto() {
      const _this = this;
      let obj = {
        url: _this.shareImg,
        text: "",
        isOpenWeixin: "0",
        successTipsText: "保存成功" //保存成功提示文案ios
      };
      this.$bridge.NativeBridge("savePhoto", obj);
      (window as any).gio("track", "web_bnt_click", {
        target_module: "保存图片",
        target_title: "双11活动主页面",
        target_id: _this.$F.userid()
      });
    },
    /**
     * 微信分享
     */
    wechatShare(type) {
      const _this = this;
      const obj = {
        type: type, //朋友圈、1: 微信、2: 微博、4: QQ好友、5:保存图片
        title: "11.11 重燃开练！Fit Vip会员买1年送1年", //分享标题
        imgUrl: this.shareImg, //分享图片
        imgActUrl: this.shareImg, //分享图片
        desc: "好友助力另赠7大超值权益礼包", //分享描述
        ctitle: "11.11 重燃开练！Fit Vip会员买1年送1年",
        olapflg: "22",
        isphoto: "1"
      };
      this.$bridge.NativeBridge("webViewShare", obj);
      this.shareFlg = false;
      if (type == 1) {
        (window as any).gio("track", "web_bnt_click", {
          target_module: "分享微信",
          target_title: "双11活动主页面",
          target_id: _this.$F.userid()
        });
      } else {
        (window as any).gio("track", "web_bnt_click", {
          target_module: "分享朋友圈",
          target_title: "双11活动主页面",
          target_id: _this.$F.userid()
        });
      }
    },
    /**
     * 跳转权益领取
     */
    goGetVip() {
      (window as any).gio("track", "web_bnt_click", {
        target_module: "查看奖励",
        target_title: "双11活动主页面",
        target_id: this.$F.userid()
      });
      window.location.href = "/subject/get2020-11-11";
    }
  }
});
