
import Vue from "vue";
import * as qiniu from "qiniu-js";
import { Toast, Lazyload, Popup } from "vant";
Vue.use(Toast)
  .use(Lazyload)
  .use(Popup);
export default Vue.extend({
  data() {
    return {
      alipay: "",
      name: "",
      tel: "",
      orderType: "", //订单类型
      fitIdImg: "", //fitid截图
      orderImg: "", //订单截图
      isClick: false //按钮是否可以点击
    };
  },
  created() {
    this.orderType = this.$route.query.orderType.toString();
    this.$bridge.NativeBridge("editTitle", "我的提现");
  },
  methods: {
    /**
     * 验证
     */
    verification() {
        if (this.alipay && this.name && this.tel.length == 11) {
          this.isClick = true;
        } else {
          this.isClick = false;
        }
    },
    /**
     * 提交申请
     */
    up() {
      let _this = this;
      _this.$axios
        .post(
          "/wuAPI/fitDcApi/fitH5Act/updActivity0AliReq",
          _this.$qs.stringify({
            "form.reqUid": _this.$F.userid(),
            "form.strAppointFlg": 0,
            "form.aliAccount": _this.alipay,
            "form.aliNm": _this.name,
            "form.aliPnum": _this.tel,
            "form.userImg": _this.fitIdImg,
            "form.orderImg": _this.orderImg
          })
        )
        .then(res => {
          if (res.data.result == "Y") {
            Toast("提交成功");
            setTimeout(function() {
              //   _this.$router.push({
              //     path: "/vip/dabiao"
              //   });
              window.history.back();
            }, 2000);
          } else {
            Toast("提交失败");
          }
        })
        .catch(res => {
          Toast("提交失败");
        });
    },
    changeHandler(e) {
      const name = e.target.dataset.type;
      const file: any = e.target.files[0];
      const key: any = (function() {
        const type = file.type.replace("image/", "");
        return "fitWeb/" + new Date().getTime() + "." + type;
      })();
      const token: any = this.$route.query.qiniuToken; //从服务器拿的并存在本地data里
      const putExtra: any = {
        fname: "",
        params: {},
        mimeType: ["image/png", "image/jpeg"]
      };
      const config: any = {
        useCdnDomain: true //使用cdn加速
      };
      const options = {
        quality: 0.3,
        noCompressIfLarger: true
      };
      qiniu.compressImage(file, options).then(data => {
        const observable: any = qiniu.upload(
          file,
          key,
          token,
          putExtra,
          config
        );
        observable.subscribe({
          next: result => {},
          error: () => {
            Toast("上传图片失败");
          },
          complete: res => {
            if (res.key) {
              Toast("上传图片成功");
              if (name == "fitIdImg") {
                this.fitIdImg = "https://img.fitapp.cn/" + res.key;
              }
              if (name == "orderImg") {
                this.orderImg = "https://img.fitapp.cn/" + res.key;
              }
              this.verification();
            }
          }
        });
      });
    }
  }
});
