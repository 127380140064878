/*
 * @Description:
 * @Autor: liuguangkuo
 * @Date: 2020-02-28 13:23:07
 * @LastEditTime: 2021-10-15 09:49:26
 */
/**
 * vip活动路由文件
 */
let routes = [
  //0元购
  {
    path: "/vip/vipMall",
    name: "vipMall",
    component: require("@/views/vip/vipMall").default,
    meta: {
      title: "VIP年会员套餐 0元购"
    }
  },
  //0元购 + 实物
  {
    path: "/vip/vipMallObject",
    name: "vipMallObject",
    component: require("@/views/vip/vipMallObject").default,
    meta: {
      title: "VIP年会员套餐 0元购"
    }
  },
  //0元购续费
  {
    path: "/vip/vipMallRenew",
    name: "vipRenew",
    component: require("@/views/vip/vipRenew").default,
    meta: {
      title: "VIP年会员续费专享"
    }
  },
  //达标
  {
    path: "/vip/dabiao",
    name: "dabiao",
    component: require("@/views/vip/dabiao").default,
    meta: {
      title: "我的0元购达标进度"
    }
  },
  //提现
  {
    path: "/vip/money",
    name: "money",
    component: require("@/views/vip/money").default,
    meta: {
      title: "我的提现"
    }
  },
  //会员特权
  {
    path: "/vip/vipTab",
    name: "vipTab",
    component: require("@/views/vip/vipTab").default,
    meta: {
      title: "会员特权"
    }
  },
  //截图示范
  {
    path: "/vip/screenshot",
    name: "screenshot",
    component: require("@/views/vip/screenshot").default,
    meta: {
      title: "截图示范"
    }
  }
];
export default routes;
