
import Vue from "vue";
import { Toast, Popup, Icon } from "vant";
Vue.use(Toast)
  .use(Popup)
  .use(Icon);
export default Vue.extend({
  data() {
    return {
      info: {
        //基础数据
        entTargetClassType: {
          sprice: "",
          discount: "",
          classTitle: "",
          priceFlg: ""
        },
        actuId: "",
        tactuId: "",
        verFlg: "0",
        qrImg: "",
        qrHImg: ""
      },
      noticeFlg: false, //服务协议
      tel: "", //电话
      errText: "", //错误提示
      iosPayShow: false //ios取消支付弹出
    };
  },
  created() {
    this.getPageInfo();
    /**
     * 页面加载设置
     */
    this.$bridge.setBridgeIsShowInfo({
      close: "1",
      pull: "1",
      prev: "1",
      noback: "0",
      slip: "1",
      service: "0"
    });
  },
  methods: {
    //获取页面数据
    getPageInfo() {
      let _this = this;
      let trainid = _this.$route.query.trainid; //活动id
      let classType = _this.$route.query.class; //classType
      let periodNums = _this.$route.query.periodNums; //periodNums
      let target =_this.$route.query.target; 
      _this.$axios
        .post(
          "/wuAPI/fitDcApi/sptWxH5Jzy/getTrainActClassResource",
          _this.$qs.stringify({
            "form.reqUid": _this.$F.userid(),
            "form.trainActId": trainid,
            "form.channel": "dc2",
            "form.periodNums": periodNums,
            "form.class_type": classType,
            "form.phoneType": _this.$B.system() == "ios" ? "1" : "0",
            "form.version": _this.$F.dev(1),
            "form.target": target
          })
        )
        .then(res => {
          window.console.log(res);
          _this.info = res.data.entRet;
          _this.tel = res.data.entRet.phoneNum;
        })
        .catch(res => {
          Toast("页面加载失败");
        });
    },
    /**
     * 提交订单
     */
    creatOrderFn() {
      //验证
      let _this = this;
      let tel = this.tel;
      if (!tel) {
        _this.showErr("请填写手机号");
        return;
      }
      if (!/^1[23456789]\d{9}$/.test(tel)) {
        _this.showErr("手机号格式错误");
        return;
      }
      if (!_this.noticeFlg) {
        _this.showErr("请阅读并同意超级减脂营用户服务协议");
        return;
      }
      //创建订单
      _this.$axios
        .post(
          "/wuAPI/fitDcApi/sptWxH5Jzy/trainActPayReq",
          _this.$qs.stringify({
            "form.reqUid": _this.$F.userid(),
            "form.trainActId": _this.$route.query.trainid,
            "form.phoneNum": _this.tel,
            "form.channel": "dc2",
            "form.periodNums": _this.$route.query.periodNums,
            "form.classType": _this.$route.query.class,
            "form.priceFlg": _this.info.entTargetClassType.priceFlg,
            "form.actuId": _this.info.actuId,
            "form.tactuId": _this.info.tactuId,
            "form.payType": "0"
          })
        )
        .then(res => {
          if (res.data.result == "Y") {
            _this.payFn();
          }
        })
        .catch(res => {
          Toast("页面加载失败");
        });
    },
    /**
     * 验证
     */
    showErr(text: string) {
      let _this = this;
      _this.errText = text;
      setTimeout(function() {
        _this.errText = "";
      }, 3000);
    },
    /**
     * 回到页面顶部
     */
    goTop() {
      if (this.$B.system() == "ios") {
        window.scrollTo(0, 0);
      }
    },
    /**
     * pay
     */
    payFn() {
      let _this = this;
      let trainid = _this.$route.query.trainid; //活动id
      var popGoodsInfo = {
        commodityId: _this.$route.query.class, //商品id
        callType: "2",
        commodityTitle: _this.info.entTargetClassType.classTitle, //商品标题
        commodityComment: _this.info.entTargetClassType.classTitle, //商品描述
        comment: _this.info.entTargetClassType.classTitle, //商品描述
        price: _this.info.entTargetClassType.sprice, //价格
        commodityType: "3",
        buyComment: "",
        buyTitle: _this.info.entTargetClassType.classTitle //商品标题
      };
      var payObj = {
        showPaySheet: "1",
        showApplePay: "0",
        goodsInfo: JSON.stringify(popGoodsInfo),
        cusColor: "#FE533B",
        callback: "successFn",
        androidStyleType: "15",
        paySort: "1,0"
      };
      if (_this.$F.dev() >= 643) {
        if (_this.$B.system() == "ios") {
          payObj.showPaySheet = "0";
          payObj.showApplePay = "1";
          payObj.paySort = "";
        }
      }
      //支付
      _this.$bridge.NativeBridge("payPop", payObj);
      //支付成功运行方法
      (window as any).successFn = function(msg: any) {
        window.console.log(msg);
        msg = JSON.parse(msg);
        if (msg.type == "success") {
          _this.$router.push({
            path: "/fat/orderDetails",
            query: {
              trainid: trainid,
              tactuid: _this.info.tactuId,
              actuId: _this.info.actuId,
              orderid: msg.orderid
            }
          });
        } else if (msg.type == "fail") {
          if (
            _this.$B.system() == "ios" &&
            _this.info.verFlg == "1" &&
            _this.$F.dev() >= 643
          ) {
            _this.iosPayShow = true;
            (window as any).sendWebMessage = function(val) {
              window.console.log(val);
              let obj = JSON.parse(val);
              window.console.log(obj);
              if (obj.name == "savePhoto" && obj.json.type == "success") {
                _this.iosPayShow = false;
              }
            };
          }
        }
      };
    },
    /**
     * 保存图片
     */
    savePhoto() {
      const _this = this;
      let obj = {
        url: this.info.qrHImg,
        text: "",
        isOpenWeixin: "0",
        successTipsText: "保存成功" //保存成功提示文案ios
      };
      this.$bridge.NativeBridge("savePhoto", obj);
    }
  }
});
