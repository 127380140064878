/*
 * @Description:
 * @Autor: liuguangkuo
 * @Date: 2020-06-30 13:34:12
 * @LastEditTime: 2020-06-30 14:22:39
 */

/**
 * 常见问题
 */
let routes = [
  //常见问题首页
  {
    path: "/question",
    name: "question",
    component: require("@/views/question/index").default,
    meta: {
      title: "健身常见问题解答"
    }
  },
  //问题分类列表
  {
    path: "/question/classList",
    name: "questionClass",
    component: require("@/views/question/classList").default,
    meta: {
      title: "健身常见问题解答"
    }
  },
  //全部热门问题
  {
    path: "/question/all",
    name: "questionAll",
    component: require("@/views/question/all").default,
    meta: {
      title: "全部热门问题"
    }
  },
  //问题详情
  {
    path: "/question/stails",
    name: "questionStails",
    component: require("@/views/question/stails").default,
    meta: {
      title: "健身常见问题解答"
    }
  }
];
export default routes;
