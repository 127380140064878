/*
 * @Description:
 * @Autor: liuguangkuo
 * @Date: 2019-12-30 16:54:41
 * @LastEditTime: 2020-12-03 16:10:30
 */
import * as B from "../../tool/browser";
/**
 * 调用客户端方法
 * name : string，执行的方法名
 *      |--- openPage 打开客户端页面
 *      |--- send 向客户端发送要执行的行为
 * params : object, 传给客端的数据
 * whoCare : number, 希望哪一端运行,默认为0: all , 1：ios,2：安卓
 */
const system = B.system();
function NativeBridge(name: string, params: any, whoCare: number = 0) {
  window.console.log(name, params);
  switch (name) {
    /**
     * 打开客户端页面
     */
    case "openPage":
      window.console.log(JSON.stringify(params));
      if (system == "ios") {
        if (whoCare != 2)
          (window as any).webkit.messageHandlers["fitOpenPage"].postMessage(
            JSON.stringify(params)
          );
      }
      /* else if (system == "android") {
        if (whoCare != 1) (window as any).Fitapp[name](JSON.stringify(params));
      } 
       因为对应鸿蒙系统注掉安卓判断
      */
      else  {
        if (whoCare != 1) (window as any).Fitapp[name](JSON.stringify(params));
      }
      break;
    /**
     * 支付
     */
    case "fitPay":
    case "payPop":
      (window as any).successFn = params.callback;
      params.callback = "successFn";
      if (system == "ios") {
        if (whoCare != 2)
          (window as any).webkit.messageHandlers[name].postMessage(params);
      } 
      /* else if (system == "android") {
        if (whoCare != 1) (window as any).Fitapp[name](JSON.stringify(params));
      }  因为对应鸿蒙系统注掉安卓判断
      */
      else  {
        if (whoCare != 1) (window as any).Fitapp[name](JSON.stringify(params));
      } 
      break;
    /**
     * 向用户端发送要执行的行为
     */
    default:
      if (system == "ios") {
        if (whoCare != 2)
          (window as any).webkit.messageHandlers[name].postMessage(params);
      }/* else if (system == "android") {
        if (typeof params == "string") {
          (window as any).Fitapp[name](params);
          return false;
        }
        if (whoCare != 1) (window as any).Fitapp[name](JSON.stringify(params));
      } 因为对应鸿蒙系统注掉安卓判断 */
       else {
        if (typeof params == "string") {
          (window as any).Fitapp[name](params);
          return false;
        }
        if (whoCare != 1) (window as any).Fitapp[name](JSON.stringify(params));
      } 
      break;
  }
}

/**
 * 设置客户端分享信息
 * @param ob j : object
 * obj = {
 *  title : '分享标题',
 *  link : '分享出去的页面链接',
 *  imgUrl : ‘分享图片,
 *  desc ：'分享描述',
 *  weiboTitle : '分享微博标题',
 *  weiboImg : '分享微博图片',
 *  olapflg : '分享来源渠道'
 *  shareList : '1,2' //显示需要分享的app列表
 * }
 * shareList ： 
    IOS和安卓渠道
    0 // 新浪
    1 // 微信好友
    2 // 朋友圈
    3 // 腾讯QQ
    4 // qq空间
    5 // 短消息
    IOS渠道
    6 // 复制链接
    8 // 更多
   *
   * 客户端右上角分享按钮
   * 使用getShareInfo：显示、不使用：不显示
 */
function setShareInfo(obj: object) {
  (window as any).getShareInfo = function() {
    return obj;
  };
}

/**
 * 设置客户端打开网页基础功能
IOS
var obj = {
    close : '1', //是否显示标题的关闭按钮 (true：显示；false : 不显示)
    pull : '1', //是否显示下拉操作 (true：显示；false : 不显示)
    prev : '1',
    noback : '1', //是否显示返回按钮 (true：不显示；false : 显示)
    service : '1', //是否显示客服
    skip : '1', //标题是否添加跳过按钮
    load : '1', //返回是否重新加载页面(0:不刷新，1:刷新)
}
安卓
var obj = {
    service : 1, //是否显示客服
    load : 0, //返回是否重新加载页面(0:不刷新，1:刷新)
    skip : '1' //标题是否添加跳过按钮
}
 */
function setBridgeIsShowInfo(obj: object) {
  if (system == "ios") {
    (window as any).iosShow = function() {
      return obj;
    };
  }
   /* else if (system == "android") {
    (window as any).androidShow = function() {
      return obj;
    };
  }因为对应鸿蒙系统注掉安卓判断 */
   else {
    (window as any).androidShow = function() {
      return obj;
    };
  }

}

/**
 * 获取客户度系统设置
{
    name : 'notification' //获取通知
}
 */
function getBridgeSetting(obj: object) {
  NativeBridge("getBridgeSetting", obj);
}
/**
 * 接受客户端数据
 */
function sendWebMessage(fn: any) {
  (window as any).sendWebMessage = function(type: object) {
    fn(type);
  };
}

export {
  NativeBridge,
  setShareInfo,
  setBridgeIsShowInfo,
  getBridgeSetting,
  sendWebMessage
};
