
import Vue from "vue";
import { Picker, Toast, Icon, Popup, Dialog } from "vant";
Vue.use(Picker)
  .use(Toast)
  .use(Icon)
  .use(Dialog)
  .use(Popup);
export default Vue.extend({
  data() {
    return {
      value: "",
      showPicker: false,
      info: {
        lstWeekDet: [{ clearFlg: 0, classDate: "", courseStatusNm: "" }],
        lstWeek: []
      }, //默认数据
      defaultIndex: "", //默认周
      defaultWeek: "", //默认周
      nowWeek: "", //当前周
      columns: [""], //选择周数据
      headImg: "",
      userNmae: "",
      weekTime: ""
    };
  },
  created() {
    let userid = this.$route.query.userid;
    let isApp = this.$route.query.isapp;
    if (isApp) {
      this.getPlanList(userid);
    } else {
      this.headImg = this.$route.query.headImg.toString();
      this.userNmae = this.$route.query.name.toString();
      this.getUserInfo();
    }
    // this.$bridge.NativeBridge("editTitle", userid);
  },
  methods: {
    /**
     * app跳转过来，需要获取用户资料
     */
    getPlanList(userid) {
      let _this = this;
      let planid = _this.$route.query.planid;
      _this.$axios
        .post(
          "/wuAPI/vipDcApi/cusPlanH5/getCusPlan",
          _this.$qs.stringify({
            "form.reqUid": "64789-vue",
            "form.userId": userid,
            "form.channel": "dc2",
            "form.reqParam": userid
          })
        )
        .then(res => {
          let data = res.data.entRet;
          if (data.lstCusPlan.length > 0) {
            for (let i = 0; i < data.lstCusPlan.length; i++) {
              window.console.log(planid, data.lstCusPlan[i].cusPlanid);
              if (planid == data.lstCusPlan[i].cusPlanid) {
                _this.headImg = data.lstCusPlan[i].headImg;
                _this.userNmae = data.lstCusPlan[i].userNmae;
              }
            }
            _this.getUserInfo();
          }
        })
        .catch(res => {
          Toast("页面加载失败");
        });
    },
    /**
     * 获取用户数据
     */
    getUserInfo(week?) {
      let _this = this;
      _this.$axios
        .post(
          "/wuAPI/vipDcApi/cusPlanH5/getCusPlanWeek",
          _this.$qs.stringify({
            "form.reqUid": "64789-vue",
            "form.userId": _this.$F.userid(),
            "form.channel": "dc2",
            "form.noWeek": week,
            "form.cusPlanId": _this.$route.query.planid
          })
        )
        .then(res => {
          window.console.log(res);
          const json = res.data;
          if (json.result === "Y") {
            //当前周选择
            if (!week) {
              this.columns = [];
              json.entRet.lstWeek.forEach((item, index) => {
                if (item.weekFlg === "1") {
                  this.defaultIndex = index;
                  this.nowWeek = index;
                  if (_this.$route.query.cusStatus != "未开始") {
                    this.defaultWeek = "当前周：" + item.noWeekNm;
                    this.columns.push("当前周：" + item.noWeekNm);
                  } else {
                    this.defaultWeek = item.noWeekNm;
                    this.columns.push(item.noWeekNm);
                  }
                } else {
                  this.columns.push(item.noWeekNm);
                }
              });
            }

            _this.info = json.entRet;
            _this.weekTime =
              json.entRet.lstWeekDet[0].startDate +
              "-" +
              json.entRet.lstWeekDet[0].endDate;
            json.entRet.lstWeekDet.forEach((item, index) => {
              if (_this.info.lstWeekDet[index].classDate) {
                _this.info.lstWeekDet[index].clearFlg = 0;
              } else {
                _this.info.lstWeekDet[index].clearFlg = 1;
              }
            });
          }
        })
        .catch(res => {
          Toast("页面加载失败");
        });
    },
    /**
     * 选择周
     */
    onConfirm(value) {
      const index =
        parseInt((this as any).$refs.picker.getIndexes().toString()) + 1;
      this.value = value;
      this.showPicker = false;
      this.defaultWeek = value;
      this.nowWeek = (index - 1).toString();
      this.getUserInfo(index);
    },
    /**
     * 清楚时间
     */
    clearTime(coursetimeId, index) {
      let _this = this;
      Dialog.confirm({
        title: "提示",
        message: "确定要清除本课程的上课时间吗?",
        confirmButtonText: "确定"
      })
        .then(() => {
          _this.$axios
            .post(
             // "/wuAPI/vipDcApi/cusPlanH5/clearCourseTime",
              "/zhangAPI/SuperCoachWeb/coachclass/clearH5CourseTime",
              _this.$qs.stringify({
                "form.reqUid": "64789-vue",
                "form.userId": _this.$F.userid(),
                "form.channel": "dc2",
                "form.coursetimeId": coursetimeId
              })
            )
            .then(res => {
              window.console.log(res);
              const json = res.data;
              if (json.result === "Y") {
                Toast("清除成功");
                _this.info.lstWeekDet[index].clearFlg = 1;
                _this.info.lstWeekDet[index].classDate = " ";
                _this.info.lstWeekDet[index].courseStatusNm = "默认排课";
              } else {
                Toast("清除失败");
              }
            })
            .catch(res => {
              Toast("页面加载失败");
            });
        })
        .catch(() => {
          // on cancel
        });
    },
    /**
     * 查看用户课时数
     */
    goCourseTime() {
      window.location.href =
        "/cjsj/courseTime?userid=" + this.$route.query.userid;
    },
    /**
     * 跳转编辑课程
     */
    editPlan(coursetimeId, courseTypeNm, editPlan) {
      if (editPlan == 1) {
        window.location.href =
          "/cjsj/editPlan?coursetimeId=" +
          coursetimeId +
          "&courseTypeNm=" +
          courseTypeNm;
      } else {
        Toast("没有可用的课时");
      }
    },
    /**
     * 跳转添加课程
     */
    goCreatePlan() {
      let userid = this.$route.query.userid;
      let planid = this.$route.query.planid;
      window.location.href =
        "/cjsj/createPlan?userid=" +
        userid +
        "&planid=" +
        planid +
        "&noweek=" +
        this.info.lstWeek[this.nowWeek].cusWeekid;
    }
  }
});
