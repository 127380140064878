/*
 * @Description:
 * @Autor: liuguangkuo
 * @Date: 2020-03-27 09:55:50
 * @LastEditTime: 2021-02-08 17:57:48
 */
let routes = [
  /**
   * 训练营首页
   */
  {
    path: "/train/index",
    name: "trainIndex",
    component: require("@/views/train/index").default,
    meta: {
      title: ""
    }
  },
  /**
   * 购买成功
   */
  {
    path: "/train/orderDetails",
    name: "orderDetails",
    component: require("@/views/train/orderDetails").default,
    meta: {
      title: "订单结果"
    }
  },
  /**
   * 填写资料
   */
  {
    path: "/train/trainTel",
    name: "trainTel",
    component: require("@/views/train/trainTel").default,
    meta: {
      title: "填写联系电话"
    }
  },
  /**
   * 加入微信群
   */
  {
    path: "/train/trainCode",
    name: "trainCode",
    component: require("@/views/train/trainCode").default,
    meta: {
      title: "添加专属教练"
    }
  }
];
export default routes;
