
import Vue from "vue";
import wxShare from "../../tool/wechat";
import { slider, slideritem } from "vue-concise-slider";
import { Toast, Lazyload, Popup } from "vant";
const iosInfo = `1. 活动时间：12月11日 - 12月14日<br />
2. 活动期间，同一用户（同一用户名、设备、手机号都视为同一用户）仅限参与1次，活动赠予权益（另赠1年、弹力带）必须在本活动页面进行购买，重复购买将不附赠1年会员权益。<br />
3. 成功购买后，实物商品如遇质量问题可联系在线客服进行退换；7日内可无理由对商品进行退货，由于Fit VIP会员服务是数字化商品，基于互联网服务的特殊性和国家相关管理规定，如因您的个人原因发生错误购买或无法正常享受会员权益，Fit不能退还相应会员费。<br />
4. Fit保留本次活动的最终解释权。`;
const anInfo = `1. 活动时间：12月11日 - 12月14日<br />
2. 活动期间，同一用户（同一用户名、设备、手机号都视为同一用户）仅限参与1次，重复购买将不附赠1年会员权益。<br />
3. 由于Fit VIP会员服务是数字化商品，基于互联网服务的特殊性和国家相关管理规定，如因您的个人原因发生错误购买或无法正常享受会员权益，Fit不能退还相应会员费。<br />
4. Fit保留本次活动的最终解释权。`;
Vue.use(Toast)
  .use(Lazyload)
  .use(Popup);
export default Vue.extend({
  mixins: [wxShare],
  data() {
    return {
      infoText: "",
      info: {
        actLiveId: "",
        userBuyStatus: "1",
        actFlg: "",
        yuyueFlg: "1",
        lstAct0GrpComData: [
          {
            grpTitle: "",
            activityPrice: ""
          }
        ],
        comdDesc: "",
        andComId: ""
      },
      show: false,
      browser: this.$B.browser(),
      system: this.$B.system(),
      notificationType: 0, //是否开启通知
      notificationFlg: false, //通知弹窗
      options: {
        loop: true,
        thresholdDistance: "50",
        autoplay: "3000"
      }
    };
  },
  created() {
    let _this = this;
    _this.getPageInfo();
    _this.infoText = _this.system == "ios" ? iosInfo : anInfo;
    /**
     * 右上角分享初始化
     */
    _this.$bridge.setShareInfo({
      title: "双12@你｜VIP年会员买一送一",
      link: _this.$F.getOrigin() + "/subject/2020-12-12",
      imgUrl: "https://cdn.fitapp.cn/vue/subject/12-12/wechat.png",
      desc: "限时赠299元真人1V1私教课，点我开启热练",
      ctitle: "双12@你｜VIP年会员买一送一",
      olapflg: "25",
      display: "1",
      shareList: "1,2"
    });
    /**
     * 设置微信分享
     */
    if (_this.browser == "wechat") {
      _this.setShare();
    }
  },
  methods: {
    /**
     * 获取基础数据
     */
    getPageInfo() {
      const _this = this;
      _this.$axios
        .post(
          "/wuAPI/fitDcApi/fitH5Act/getGrpActComResource",
          _this.$qs.stringify({
            "form.reqUid": "64789-vue",
            "form.userId": _this.$F.userid() == 1 ? "10092" : _this.$F.userid(),
            "form.mbType": _this.$B.system() == "ios" ? "1" : "0",
            "form.channel": "dc2",
            "form.grpType": "9"
          })
        )
        .then(res => {
          window.console.log(res);
          if (res.data.result == "Y") {
            _this.info = res.data.entRet;
            /**
             * 判断是否到12.14日
             */
            if (new Date().getTime() > 1607875199000) {
              _this.info.yuyueFlg = "3";
            }
          } else {
            Toast("页面加载失败");
          }
        })
        .catch(res => {
          Toast("页面加载失败");
        });
    },
    /**
     * 打开在线客服
     */
    openCustomService() {
      if (this.browser == "wechat") {
        this.goFit();
        return false;
      }
      this.$openPage.NativeOpenPage("3", "xiaoshou");
    },
    /**
     * 微信打开活动页面openFit
     */
    goFit() {
      this.$openPage.openFit("1", {
        url: this.$F.getOrigin() + "/subject/2020-12-12"
      });
    },
    /**
     * 微信分享
     */
    setShare() {
      const shareInfo = {
        title: `双12@你｜VIP年会员买一送一`,
        desc: `限时赠299元真人1V1私教课，点我开启热练`,
        link: this.$F.getOrigin() + "/subject/2020-12-12",
        img: "https://cdn.fitapp.cn/vue/subject/12-12/wechat.png"
      };
      (this as any).wechatShare(shareInfo);
    },
    /**
     * 支付
     */
    payFn() {
      const _this = this;
      if (_this.browser == "wechat") {
        _this.goFit();
        return false;
      }
      if (_this.info.actFlg == "-1") {
        Toast("活动未开始");
        return false;
      }
      if (_this.info.actFlg == "-2") {
        Toast("活动已结束");
        return false;
      }
      if (_this.$F.dev() >= 620) {
        const popGoodsInfo = {
          commodityId: _this.info.andComId, //商品id
          callType: "5",
          commodityTitle: _this.info.lstAct0GrpComData[0].grpTitle, //商品标题
          commodityComment: _this.info.comdDesc, //商品描述
          comment: _this.info.comdDesc, //商品描述
          price: _this.info.lstAct0GrpComData[0].activityPrice, //价格
          commodityType: "3",
          buyComment: _this.info.comdDesc,
          buyTitle: _this.info.lstAct0GrpComData[0].grpTitle //商品标题
        };
        let payObj = {
          showApplePay: "0",
          goodsInfo: JSON.stringify(popGoodsInfo),
          cusColor: "#DBB76A",
          androidStyleType: "0",
          callback: "successFn",
          paySort: "0,1",
          showPaySheet: "0"
        };

        if (_this.$F.dev() >= 635 && _this.$B.system() == "ios") {
          payObj.showPaySheet = "0";
          payObj.showApplePay = "1";
          payObj.paySort = "";
        }
        //支付
        if (_this.$B.system() == "ios") {
          _this.$openPage.NativeOpenPage("5", _this.info.lstAct0GrpComData[0]);
        } else {
          _this.$bridge.NativeBridge("payPop", payObj);
        }
        (window as any).successFn = function(msg) {
          msg = JSON.parse(msg);
          if (msg.type == "success") {
            _this.info.userBuyStatus = "2";
            if (_this.$B.system() != "ios") {
              Toast("支付成功");
            }
          }
        };
      } else {
        Toast("请下载最新版app！");
      }
    },
    /**
     * 预约
     */
    reserveFn() {
      let _this = this;
      if (_this.browser == "wechat") {
        _this.goFit();
        return false;
      }
      _this.$bridge.getBridgeSetting({ name: "notification" });
      _this.$bridge.sendWebMessage(function(type) {
        if (_this.$B.system() == "ios") {
          _this.notificationType = JSON.parse(type).type;
        } else {
          _this.notificationType = type;
        }
        if (_this.notificationType == 0) {
          _this.notificationFlg = true;
        }
        _this.$axios
          .post(
            "/wuAPI/fitDcApi/fitPush/procSpecialPushReq.do",
            _this.$qs.stringify({
              "form.reqUid": "64789-vue",
              "form.channel": "dc2",
              "form.userId": _this.$F.userid(),
              "form.targetId": _this.info.actLiveId,
              "form.genFlg": "fitQiniuLiveYuyueAct"
            })
          )
          .then(res => {
            if (_this.notificationType == 1) {
              Toast({
                message: "预约成功，开始前15分钟会有<br />消息通知您",
                type: "html"
              });
            }
            _this.info.yuyueFlg = "2";
            if (_this.$F.dev() >= 643) {
              _this.$bridge.NativeBridge("liveSubscribeSuccess", {
                id: _this.info.actLiveId
              });
            }
          })
          .catch(err => {
            Toast("页面加载失败");
          });
      });
    },
    /**
     * 打开通知
     */
    openSetting() {
      this.$openPage.NativeOpenPage("4");
      this.notificationFlg = false;
      this.info.yuyueFlg = "2";
    },
    /**
     * 关闭通知弹出
     */
    closeNotificationFn() {
      let _this = this;
      _this.notificationFlg = false;
      setTimeout(function() {
        _this.info.yuyueFlg = "2";
        Toast({
          message: "预约成功",
          type: "html"
        });
      }, 500);
    },
    /**
     * 微信通知
     */
    wechatMessage() {
      const _this = this;
      const scene = "1000";
      const obj = {
        appid: "",
        scene: scene,
        template_id: "8JVFd2jeUB2gmQqfTfPoftnaSTSCNiBZNf4IKoV0rZw",
        reserved: new Date().getTime()
      };
      this.$bridge.NativeBridge("wechatPushAuth", obj);
      /**接受客户端数据 */
      (window as any).sendWebMessage = function(val) {
        let obj = JSON.parse(val);
        window.console.log(obj, 312312);
        if (obj.name == "wechatPushAuth") {
          if (obj.json.action == "confirm") {
            _this.wechatPush(obj.json.openId, obj.json.templateId, scene);
            Toast({
              message: "预约成功，开始前15分钟会有<br />消息通知您",
              type: "html"
            });
          } else {
            _this.notificationFlg = false;
            Toast({
              message: "预约成功",
              type: "html"
            });
          }
        }
      };
    },
    /**
     * 微信推送
     */
    wechatPush(openid, template_id, scene) {
      const _this = this;
      _this.$axios
        .post(
          "/wuAPI/fitDcApi/fitH5Act/getWxLiveYuyueResource",
          _this.$qs.stringify({
            "form.reqUid": "64789-vue",
            "form.channel": "dc2",
            "form.userId": _this.$F.userid(),
            "form.lvId": _this.info.actLiveId,
            "form.openid": openid,
            "form.tempId": template_id,
            "form.scene": scene
          })
        )
        .then(res => {
          _this.notificationFlg = false;
        })
        .catch(err => {
          Toast("页面加载失败");
        });
    },
    /**
     * 跳转直播页面
     */
    goLive() {
      window.location.href = "/subject/live";
    }
  },
  components: {
    slider,
    slideritem
  }
});
