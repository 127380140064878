
import Vue from "vue";
import { Toast, Swipe, SwipeItem, Lazyload, Icon, Popup } from "vant";
Vue.use(Toast)
  .use(Swipe)
  .use(SwipeItem)
  .use(Lazyload)
  .use(Icon)
  .use(Popup);
export default Vue.extend({
  data() {
    return {
      info: ""
    };
  },
  created() {
    this.getPageInfo();
  },
  methods: {
    //获取页面数据
    getPageInfo() {
      let _this = this;
      let userid = _this.$route.query.userid
        ? _this.$route.query.userid 
        : _this.$F.userid() == 1
        ? "10092"
        : _this.$F.userid();
      _this.$axios
        .post(
          "/wuAPI/vipDcApi/cusPlanH5/getReportImg",
          _this.$qs.stringify({
            "form.reqUid": "64789-vue",
            "form.userId": userid,
            "form.channel": "dc2",
            "form.coursetimeId": _this.$route.query.coursetimeid
          })
        )
        .then(res => {
          _this.info = res.data.entRet;
        })
        .catch(res => {
          Toast("页面加载失败");
        });
    }
  }
});
