var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"head"},[_c('img',{attrs:{"src":"https://cdn.fitapp.cn/vue/subject/2021-12-12/p1.png"}}),_c('div',{staticClass:"btn"},[(
          (_vm.info.lstCom[0].comFlg == 0 && _vm.info.lstCom[0].actFlg == 1) ||
            (_vm.info.lstCom[0].comFlg == 0 && _vm.info.lstCom[0].actFlg == -2)
        )?_c('img',{staticClass:"pulse",attrs:{"src":"https://cdn.fitapp.cn/vue/subject/2021-12-12/btn_01.png","alt":""},on:{"click":function($event){return _vm.payFn(0)}}}):_vm._e(),(_vm.info.lstCom[0].comFlg == 1)?_c('img',{attrs:{"src":"https://cdn.fitapp.cn/vue/subject/2021-12-12/btn_01_2.png","alt":""}}):_vm._e()]),_c('div',{staticClass:"info",on:{"click":function($event){_vm.show = true}}},[_c('img',{attrs:{"src":"https://cdn.fitapp.cn/vue/subject/2021-12-12/gz.png","alt":""}})])]),_c('img',{attrs:{"src":"https://cdn.fitapp.cn/vue/subject/2021-12-12/p2.png","alt":""}}),_c('div',{staticClass:"make"},[_c('ul',_vm._l((_vm.info.entMeda.lstPlan),function(item,index){return _c('li',{key:index,on:{"click":function($event){item.topicFlg == '0'
            ? _vm.goTrain(item.targetId)
            : _vm.goSubject(item.targetId)}}},[_c('img',{attrs:{"src":item.targetImgLnk}}),_c('div',{staticClass:"make-bg"},[_c('p',[_vm._v(_vm._s(item.targetNm))]),_c('strong',[_vm._v(_vm._s(item.joinNum)+"人已参加")]),_vm._m(0,true)])])}),0)]),_c('div',{staticClass:"foot-hd"}),_c('div',{staticClass:"foot"},[_c('div',{staticClass:"foot-icon",on:{"click":_vm.openCustomService}},[_c('img',{attrs:{"src":"https://cdn.fitapp.cn/vue/subject/2021-12-12/icon_kf.png","alt":""}})]),(
        (_vm.info.lstCom[0].comFlg == 0 && _vm.info.lstCom[0].actFlg == 1) ||
          (_vm.info.lstCom[0].comFlg == 0 && _vm.info.lstCom[0].actFlg == -2)
      )?_c('div',{staticClass:"foot-btn",on:{"click":function($event){return _vm.payFn(0)}}},[_vm._v(" 立即开通 ")]):_vm._e(),(_vm.info.lstCom[0].comFlg == 1)?_c('div',{staticClass:"foot-btn foot-btn-no"},[_vm._v(" 已开通 ")]):_vm._e()]),_c('van-popup',{staticClass:"popup-box",attrs:{"position":"bottom"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('div',{staticClass:"popup-tt"},[_vm._v("活动规则")]),_c('div',{staticClass:"popup-info",domProps:{"innerHTML":_vm._s(_vm.infoText)}})])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('span',[_c('img',{attrs:{"src":"https://cdn.fitapp.cn/vue/subject/2021-12-12/icon.png","alt":""}})])
}]

export { render, staticRenderFns }