/*
 * @Author: your name
 * @Date: 2021-03-31 14:13:34
 * @LastEditTime: 2021-05-11 09:46:47
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /www/web-fit-vue/src/router/superCoach.ts
 */
/*
 * 超级私教用户端
 */
let routes = [
  //上课指南
  {
    path: "/superCoach/lessonGuide",
    name: "lessonGuide",
    component: require("@/views/superCoach/lessonGuide").default,
    meta: {
      title: "上课指南"
    }
  },
  //课程分享
  {
    path: "/superCoach/planShare",
    name: "planShare",
    component: require("@/views/superCoach/planShare").default,
    meta: {
      title: ""
    }
  },
  //关于超级私教
  {
    path: "/superCoach/about",
    name: "about",
    component: require("@/views/superCoach/about").default,
    meta: {
      title: "关于超级私教"
    }
  },
  //关于超级私教
  {
    path: "/superCoach/report",
    name: "report",
    component: require("@/views/superCoach/report").default,
    meta: {
      title: "阶段性评估报告"
    }
  }
];
export default routes;
