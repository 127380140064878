
import Vue from "vue";
import { Swipe, SwipeItem, Toast, Popup, Icon } from "vant";
const rule = [
  `1. 活动时间：4月12日早10点-4月19日24点；<br />
    2. 活动期间，同一用户（同一用户名、设备、手机号都视为同一用户）仅限参与1次；<br />
    3. 拼团活动不限制人数，仅限活动页购买参团，用户参团后，加赠1年会员权益自动到账；拼团活动结束后，平台审核参与拼单人数以后，在3个工作日内发放加赠会员天数权益；<br />
    4. 拼团活动不与其他任何会员活动同享；<br />
    5. 成功购买后，由于Fit VIP会员服务是数字化商品，基于互联网服务的特殊性和国家相关管理规定，如因您的个人原因发生错误购买或无法正常享受会员权益，Fit不能退还相应会员费；<br />
    6. Fit保留本次活动在法律范围内的最终解释权。`,
  `1. 活动时间：4月12日早10点-4月19日24点；<br />
    2. 活动期数：减脂营第70期；<br />
    3. 活动期间，同一用户（同一用户名、设备、手机号都视为同一用户）仅限参与1次；<br />
    4. 活动仅限减脂营新用户和回购老用户（上个订单已结束超过2个月）参加；<br />
    5. 成功购买后，由于减脂营服务是数字化商品，基于互联网服务的特殊性和国家相关管理规定，如因您的个人原因发生错误购买或无法正常享受减脂营服务，Fit不能退还相应费用；<br />
    6. Fit保留本次活动在法律范围内的最终解释权。`,
  `1. 活动时间：4月12日早10点-4月19日24点；<br />
    2. 活动期间，同一用户（同一用户名、设备、手机号都视为同一用户）仅限参与1次；<br />
    3. 活动仅限训练营新用户和回购老用户（上个订单已结束超过2个月）参加；<br />
    4. 成功购买后，由于训练营服务是数字化商品，基于互联网服务的特殊性和国家相关管理规定，如因您的个人原因发生错误购买或无法正常享受训练营服务，Fit不能退还相应会员费用；<br />
    5. Fit保留本次活动在法律范围内的最终解释权。`
];
Vue.use(Swipe)
  .use(SwipeItem)
  .use(Toast)
  .use(Icon)
  .use(Popup);
export default Vue.extend({
  data() {
    return {
      info: {
        lstCom: [
          {
            commodityId: "",
            commodityTitle: "",
            commodityDesc: "",
            commodityPrice: "",
            comFlg: 0,
            joinNum: 0
          },
          {
            commodityId: "",
            commodityTitle: "",
            commodityDesc: "",
            commodityPrice: "",
            comFlg: 0,
            periodNums: ""
          },
          {
            commodityId: "",
            commodityTitle: "",
            commodityDesc: "",
            commodityPrice: "",
            comFlg: 0,
            tarVal: ""
          }
        ]
      },
      fatInfo: {},
      infoText: "",
      ruleShow: false,
      vipShow: false,
      fatShow: false,
      tel: "",
      lineNum1: "0", //解锁百分比
      lineNum2: "0", //解锁百分比
      lineNum3: "0", //解锁百分比
      lineNum4: "0" //解锁百分比
    };
  },
  created() {
    this.getPageInfo();
  },
  methods: {
    /**
     * 获取基础数据
     */
    getPageInfo(reqFlg = 1) {
      const _this = this;
      _this.$axios
        .post(
          "/wuAPI/fitDcApi/fitH5Act/getGrpActComMcResource",
          _this.$qs.stringify({
            "form.reqUid": "64789-vue",
            "form.userId": _this.$F.userid() == 1 ? "10092" : _this.$F.userid(),
            "form.channel": "dc2",
            "form.mbType": _this.$B.system() == "ios" ? "1" : "0",
            "form.grpType": "210412ACT",
            "form.version": _this.$F.dev(1)
          })
        )
        .then(res => {
          if (res.data.result == "Y") {
            let entRet = res.data.entRet;
            _this.info = entRet;
            _this.tuanNum();
          } else {
            Toast("页面加载失败");
          }
        })
        .catch(res => {
          Toast("页面加载失败");
        });
    },
    tuanNum() {
      //计算解锁奖励
      let z: any =
        (this as any).info.lstCom[0].joinNum > 5000
          ? 5000
          : (this as any).info.lstCom[0].joinNum;
      let proportion = 0;
      if (z <= 500) {
        proportion = (z / 500) * 100;
        this.lineNum1 = proportion + "%";
      } else if (z > 500 && z <= 1000) {
        z = z - 500;
        proportion = (z / 500) * 100;
        (this.lineNum1 = "100%"), (this.lineNum2 = proportion + "%");
      } else if (z > 1000 && z <= 3000) {
        window.console.log(3123);
        z = z - 1000;
        proportion = (z / 2000) * 100;
        (this.lineNum1 = "100%"),
          (this.lineNum2 = "100%"),
          (this.lineNum3 = proportion + "%");
      } else if (z > 3000 && z <= 5000) {
        z = z - 3000;
        proportion = (z / 2000) * 100;
        (this.lineNum1 = "100%"),
          (this.lineNum2 = "100%"),
          (this.lineNum3 = "100%"),
          (this.lineNum4 = proportion + "%");
      }
    },
    /**
     * 活动规则
     */
    rule(index) {
      this.ruleShow = true;
      this.infoText = rule[index];
    },
    /**
     * 购买减脂营
     */
    buyFat() {
      if (this.info.lstCom[1].comFlg == 7) {
        Toast("该活动仅限新用户和回购老用户参加");
        return false;
      }
      window.location.href =
        "/fat/creatOrder?trainid=15&class=" +
        this.info.lstCom[1].commodityId +
        "&periodNums=" +
        this.info.lstCom[1].periodNums;
    },
    /**
     * 支付
     */
    payFn(index) {
      const _this = this;
      if (_this.$F.dev() >= 620) {
        const popGoodsInfo = {
          commodityId: _this.info.lstCom[index].commodityId, //商品id
          callType: "2",
          commodityTitle: _this.info.lstCom[index].commodityTitle, //商品标题
          commodityComment: _this.info.lstCom[index].commodityDesc, //商品描述
          comment: _this.info.lstCom[index].commodityDesc, //商品描述
          price: _this.info.lstCom[index].commodityPrice, //价格
          commodityType: "3",
          buyComment: _this.info.lstCom[index].commodityDesc,
          buyTitle: _this.info.lstCom[index].commodityTitle //商品标题
        };
        let payObj = {
          showPaySheet: "1",
          showApplePay: "0",
          goodsInfo: JSON.stringify(popGoodsInfo),
          cusColor: "#DBB76A",
          callback: "successFn",
          androidStyleType: "15",
          paySort: "1,0"
        };
        if (_this.$F.dev() >= 635 && _this.$B.system() == "ios") {
          payObj.showPaySheet = "0";
          payObj.showApplePay = "1";
          payObj.paySort = "";
        }
        //支付
        _this.$bridge.NativeBridge("payPop", payObj);
        (window as any).successFn = function(msg) {
          msg = JSON.parse(msg);
          if (msg.type == "success") {
            _this.info.lstCom[index].comFlg = 1;
            _this.$bridge.NativeBridge("sendNativeMessage", {
              name: "energyExchange"
            });
            _this.getPageInfo();
          }
        };
      }
    },
    goPlan() {
      this.$openPage.NativeOpenPage("17");
    },
    /**
     * 复制打开微信
     */
    copyFn() {
      var obj = {
        text: this.info.lstCom[2].tarVal,
        needOpenWexin: "0"
      };
      this.$bridge.NativeBridge("copyAction", obj, 0);
      this.$openPage.NativeOpenPage("10");
    }
  }
});
