/*
 * @Description:
 * @Autor: liuguangkuo
 * @Date: 2019-12-11 17:31:42
 * @LastEditTime: 2020-09-25 18:20:27
 */

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import cookie from "vue-cookies";
/**
 * flexible
 */
import "amfe-flexible/index.js";

/**
 * css reset
 */
import "@/assets/scss/reset.scss";

/**
 * 在测试环境添加vconsole
 */
// import Vconsole from "vconsole";
// if (process.env.NODE_ENV == "development" || process.env.NODE_ENV == "dev") {
//   const vConsole: any = new Vconsole();
//   Vue.use(vConsole);
// }

/**
 * axios
 */
import axios from "@/tool/axios";
Vue.prototype.$axios = axios;
/**
 * fit组件
 */
import * as bridge from "@/tool/bridge"; //调用客户端方法
Vue.prototype.$bridge = bridge;
import * as B from "@/tool/browser"; //获取浏览器
Vue.prototype.$B = B;
import * as F from "@/tool/fitTool"; //获取fit用户信息
Vue.prototype.$F = F;
import * as openPage from "@/tool/bridge/openPage"; //打开客户端或第三方应用
Vue.prototype.$openPage = openPage;

/**
 * qs
 */
import qs from "qs";
Vue.prototype.$qs = qs;

/**
 * 设置页面标题
 */
if (window.navigator.userAgent.indexOf("AlipayClient") == -1) {
  Vue.use(require("vue-wechat-title"));
}
Vue.config.productionTip = false;

/**
 * vantToast
 */
import { Toast } from "vant";
Vue.use(Toast);

/**
 * 获取token
 */
// router.beforeEach(function (to, from, next) {
//   window.console.log(to.meta.auth);
//   if(to.meta.auth){ //判断该页面是否需要验证token
//     if((cookie as any).get('fitToken')){
//       next()
//     }else{
//       getToken(next);
//     }
//   }else{
//     next()
//   }
// })

// /**
//  * 请求token
//  */
// let getTokenNum : number = 0;
// function getToken(next : any){
//   axios
//   .post(
//     "/wuAPI/fitDcApi/sptCom/getDcApiSignKeyReq",
//     qs.stringify({
//       "form.reqUid": F.userid(),
//       "form.channel": "dc2"
//     })
//   )
//   .then(res => {
//     window.console.log(res);
//     if(res.data.result == 'Y'){
//       (cookie as any).set('fitToken',res.data.entRet.priKey,res.data.entRet.expireTimes)
//       next()
//     }else{
//       if(getTokenNum >1){
//         getTokenNum++
//         getToken(next)
//       }else{
//         Toast("获取token失败");
//       }
//     }
//   })
//   .catch(res => {
//     Toast("获取token失败");
//   });
// }

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
