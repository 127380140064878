
import Vue from "vue";
import { Picker, Toast, Icon, Popup } from "vant";
Vue.use(Picker)
  .use(Toast)
  .use(Icon)
  .use(Popup);
export default Vue.extend({
  data() {
    return {
      totalTime: "",
      incompleteTime: "",
      finishTime: "",
      absentSTime: ""
    };
  },
  created() {
    (this.totalTime = this.$route.query.totalTime.toString()),
      (this.incompleteTime = this.$route.query.incompleteTime.toString()),
      (this.finishTime = this.$route.query.finishTime.toString()),
      (this.absentSTime = this.$route.query.absentSTime.toString());
  }
});
