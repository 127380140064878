/*
 * @Description:
 * @Autor: liuguangkuo
 * @Date: 2020-03-06 11:30:52
 * @LastEditTime: 2020-09-25 16:47:23
 */
/**
 * 静态页面路由文件
 */
let routes = [
  //支付宝7天会员
  {
    path: "/ali/vipFree",
    name: "vipFree",
    component: require("@/views/ali/vipFree").default,
    meta: {
      title: "7天会员免费领"
    }
  },
  //支付宝7天会员活动规则
  {
    path: "/ali/vipFreeInfo",
    name: "vipFreeInfo",
    component: require("@/views/ali/vipFreeInfo").default,
    meta: {
      title: "活动规则"
    }
  }
];
export default routes;
