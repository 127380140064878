
import Vue from "vue";
import { Swipe, SwipeItem, Toast, Popup, Icon } from "vant";
Vue.use(Swipe)
  .use(SwipeItem)
  .use(Toast)
  .use(Icon)
  .use(Popup);
export default Vue.extend({
  data() {
    return {
      info: {
        lstCom: [
          {
            actPrice: "",
            actTitle: "",
            comFlg: "",
            commodityDesc: "",
            commodityId: "",
            commodityPrice: "",
            commodityTitle: "",
            redeemCode: ""
          }
        ]
      }
    };
  },
  created() {
    this.getPageInfo();
  },
  methods: {
    /**
     * 获取基础数据
     */
    getPageInfo(reqFlg = 1) {
      const _this = this;
      _this.$axios
        .post(
          "/wuAPI/fitDcApi/fitH5Act/getGrpActComResource",
          _this.$qs.stringify({
            "form.reqUid": "64789-vue",
            "form.userId": _this.$F.userid() == 1 ? "10092" : _this.$F.userid(),
            "form.channel": "dc2",
            "form.mbType": _this.$B.system() == "ios" ? "1" : "0",
            "form.grpType": "21TOUCANACT",
            "form.version": _this.$F.dev(1)
          })
        )
        .then(res => {
          if (res.data.result == "Y") {
            let entRet = res.data.entRet;
            _this.info = entRet;
          } else {
            Toast("页面加载失败");
          }
        })
        .catch(res => {
          Toast("页面加载失败");
        });
    },
    /**
     * 支付
     */
    payFn(index) {
      const _this = this;
      if (_this.$F.dev() >= 620) {
        const popGoodsInfo = {
          commodityId: _this.info.lstCom[index].commodityId, //商品id
          callType: "2",
          commodityTitle: _this.info.lstCom[index].commodityTitle, //商品标题
          commodityComment: _this.info.lstCom[index].commodityDesc, //商品描述
          comment: _this.info.lstCom[index].commodityDesc, //商品描述
          price: _this.info.lstCom[index].commodityPrice, //价格
          commodityType: "3",
          buyComment: _this.info.lstCom[index].commodityDesc,
          buyTitle: _this.info.lstCom[index].commodityTitle //商品标题
        };
        let payObj = {
          showPaySheet: "1",
          showApplePay: "0",
          goodsInfo: JSON.stringify(popGoodsInfo),
          cusColor: "#DBB76A",
          callback: "successFn",
          androidStyleType: "15",
          paySort: "1,0"
        };
        if (_this.$F.dev() >= 635 && _this.$B.system() == "ios") {
          payObj.showPaySheet = "0";
          payObj.showApplePay = "1";
          payObj.paySort = "";
        }
        //支付
        _this.$bridge.NativeBridge("payPop", payObj);
        (window as any).successFn = function(msg) {
          msg = JSON.parse(msg);
          if (msg.type == "success") {
            _this.info.lstCom[index].comFlg = "1";
            _this.$bridge.NativeBridge("sendNativeMessage", {
              name: "energyExchange"
            });
            _this.getPageInfo();
          }
        };
      }
    },
    /**
     * 查看邀请码
     */
    goCode() {
      window.location.href =
        "/subject/2021-4-22-code?code=" + this.info.lstCom[0].redeemCode;
    }
  }
});
