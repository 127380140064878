/*
 * @Description:
 * @Autor: liuguangkuo
 * @Date: 2019-12-06 17:03:42
 * @LastEditTime: 2021-12-30 10:40:15
 */
const ua: string =
  window.location.hostname == "localhost"
    ? "Mozilla/5.0 (iPhone; CPU iPhone OS 10_2 like Mac OS X) AppleWebKit/602.3.12 (KHTML, like Gecko) Mobile/14C92 Fit_6.5.1 FitUID/164744 FitSex/1 FitChannel/huawei isTourist/0".toLowerCase()
    : navigator.userAgent.toLowerCase();

//46
// const ua: string =
//   window.location.hostname == "localhost"
//     ? "Mozilla/5.0 (Linux; Android 5.0; SM-G900P Build/LRX21T) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/80.0.3987.149 Mobile Safari/537.36 Fit_6.4.3 FitUID/3032747 FitSex/0 FitChannel/huawei isTourist/0".toLowerCase()
//     : navigator.userAgent.toLowerCase();

// const ua: string = navigator.userAgent.toLowerCase();

/**
 * 验证
 * @param rep 正则表达式
 */
function testUa(rep: any): string {
  return rep.test(ua);
}

/**
 * 获取系统
 * return windows、macos、linux、android、ios
 */
function system(): string {
  const systemRep: Array<any> = [
    {
      name: "windows",
      rep: /windows|win32|win64|wow32|wow64/g, //windows
    },
    {
      name: "macos",
      rep: /macintosh|macintel/g, //macos
    },
    {
      name: "linux",
      rep: /x11/g, //linux
    },
    {
      name: "android",
      rep: /android|adr/g, //android
    },
    {
      name: "ios",
      rep: /ios|iphone|ipad|ipod|iwatch/g, //ios
    },
  ];
  let system = "unknown";
  for (let i = 0; i < systemRep.length; i++) {
    if (testUa(systemRep[i].rep)) {
      system = systemRep[i].name;
    }
  }
  return system;
}

/**
 * 获取平台
 * return pc、mobile
 */
function device(): string {
  let device = "unknow";
  let _system = system();
  if (_system === "windows" || _system === "macos" || _system === "linux") {
    device = "pc"; // pc端
  } else if (_system === "android" || _system === "ios" || testUa(/mobile/g)) {
    device = "mobile"; // 移动端
  }
  return device;
}

/**
 * 获取浏览器
 *
 */
function browser(): string {
  const browserRep: Array<any> = [
    {
      name: "safari",
      rep: /safari/g, //safari浏览器
    },
    {
      name: "chrome",
      rep: /chrome/g, //chrome浏览器
    },
    {
      name: "ie",
      rep: /trident|compatible|msie/g, //ie浏览器
    },
    {
      name: "edge",
      rep: /edge/g, //edge浏览器
    },
    {
      name: "firefox",
      rep: /firefox/g, //firefox浏览器
    },
    {
      name: "chromium",
      rep: /chromium/g, //chromium浏览器
    },
    {
      name: "opera",
      rep: /opera|opr/g, //Opera浏览器
    },
    {
      name: "360",
      rep: /qihoobrowser|qhbrowser|360ee|360se/g, //360浏览器
    },
    {
      name: "uc",
      rep: /uc|ubrowser/g, //uc浏览器
    },
    {
      name: "qqBrowser",
      rep: /qqBrowser/g, //QQ浏览器
    },
    {
      name: "baidu",
      rep: /baidu|bidubrowser|baiduboxapp/g, //百度浏览器
    },
    {
      name: "sogou",
      rep: /metasr|sogou/g, //搜狗浏览器
    },
    {
      name: "2345",
      rep: /2345explorer|mb2345browser/g, //2345浏览器
    },
    {
      name: "liebao",
      rep: /lbbrowser/g, //猎豹浏览器
    },
    {
      name: "theworld",
      rep: /theworld/g, //世界之窗
    },
    {
      name: "maxthon",
      rep: /maxthon/g, //遨游
    },
    {
      name: "xiaomi",
      rep: /miuibrowser/g, //小米
    },
    {
      name: "huawei",
      rep: /build\/huawei/g, //华为
    },
    {
      name: "qq",
      rep: /qq\//g, //手机QQ
    },
    {
      name: "wechat",
      rep: /micromessenger/g, //微信
    },
    {
      name: "taobao",
      rep: /aliapp\(tb/g, //淘宝
    },
    {
      name: "alipay",
      rep: /aliapp\(ap/g, //支付宝
    },
    {
      name: "weibo",
      rep: /weibo/g, //微博
    },
    {
      name: "dingtalk",
      rep: /dingtalk/g, //钉钉
    },
    {
      name: "iqiyi",
      rep: /iqiyiapp/g, //爱奇艺
    },
    {
      name: "fit",
      rep: /fit/g, //fit健身
    },
  ];
  let browser = "unknown";
  for (let i = 0; i < browserRep.length; i++) {
    if (testUa(browserRep[i].rep)) {
      browser = browserRep[i].name;
    }
  }
  return browser;
}

/**
 * 获取浏览器版本
 *
 */
function version(): string {
  let versionRep: any = {
    safari: function() {
      return ua.replace(/^.*version\/([\d.]+).*$/, "$1");
    },
    chrome: function() {
      return ua
        .replace(/^.*chrome\/([\d.]+).*$/, "$1")
        .replace(/^.*crios\/([\d.]+).*$/, "$1");
    },
    ie: function() {
      return ua
        .replace(/^.*msie ([\d.]+).*$/, "$1")
        .replace(/^.*rv:([\d.]+).*$/, "$1");
    },
    edge: function() {
      return ua
        .replace(/^.*edge\/([\d.]+).*$/, "$1")
        .replace(/^.*edg\/([\d.]+).*$/, "$1");
    },
    firefox: function() {
      return ua
        .replace(/^.*firefox\/([\d.]+).*$/, "$1")
        .replace(/^.*fxios\/([\d.]+).*$/, "$1");
    },
    "firefox focus": function() {
      return ua.replace(/^.*focus\/([\d.]+).*$/, "$1");
    },
    chromium: function() {
      return ua.replace(/^.*chromium\/([\d.]+).*$/, "$1");
    },
    opera: function() {
      return ua
        .replace(/^.*opera\/([\d.]+).*$/, "$1")
        .replace(/^.*opr\/([\d.]+).*$/, "$1");
    },
    "360": function() {
      return ua.replace(/^.*qihoobrowser\/([\d.]+).*$/, "$1");
    },
    "360se": function() {
      let hash: any = {
        "63": "10.0",
        "55": "9.1",
        "45": "8.1",
        "42": "8.0",
        "31": "7.0",
        "21": "6.3",
      };
      let chrome_vision = ua.replace(/^.*chrome\/([\d]+).*$/, "$1");
      return hash[chrome_vision] || "";
    },
    "360ee": function() {
      let hash: any = {
        "69": "11.0",
        "63": "9.5",
        "55": "9.0",
        "50": "8.7",
        "30": "7.5",
      };
      let chrome_vision = ua.replace(/^.*chrome\/([\d]+).*$/, "$1");
      return hash[chrome_vision] || "";
    },
    maxthon: function() {
      return ua.replace(/^.*maxthon\/([\d.]+).*$/, "$1");
    },
    qqbrowser: function() {
      return ua.replace(/^.*qqbrowser\/([\d.]+).*$/, "$1");
    },
    qq: function() {
      return ua.replace(/^.*qq\/([\d.]+).*$/, "$1");
    },
    baidu: function() {
      return ua
        .replace(/^.*bidubrowser[\s/]([\d.]+).*$/, "$1")
        .replace(/^.*baiduboxapp\/([\d.]+).*$/, "$1");
    },
    uc: function() {
      return ua.replace(/^.*uc?browser\/([\d.]+).*$/, "$1");
    },
    sogou: function() {
      return ua
        .replace(/^.*se ([\d.x]+).*$/, "$1")
        .replace(/^.*sogoumobilebrowser\/([\d.]+).*$/, "$1");
    },
    liebao: function() {
      let hash: any = {
        "57": "6.5",
        "49": "6.0",
        "46": "5.9",
        "42": "5.3",
        "39": "5.2",
        "34": "5.0",
        "29": "4.5",
        "21": "4.0",
      };
      let chrome_vision: any = ua.replace(/^.*chrome\/([\d]+).*$/, "$1");
      return hash[chrome_vision] || "";
    },
    "2345explorer": function() {
      let hash: any = { "69": "10.0", "55": "9.9" };
      let chrome_vision: any = ua.replace(/^.*chrome\/([\d]+).*$/, "$1");
      return (
        hash[chrome_vision] ||
        ua
          .replace(/^.*2345explorer\/([\d.]+).*$/, "$1")
          .replace(/^.*mb2345browser\/([\d.]+).*$/, "$1")
      );
    },
    theworld: function() {
      return ua.replace(/^.*theworld ([\d.]+).*$/, "$1");
    },
    xiaomi: function() {
      return ua.replace(/^.*miuibrowser\/([\d.]+).*$/, "$1");
    },
    wechat: function() {
      return ua.replace(/^.*micromessenger\/([\d.]+).*$/, "$1");
    },
    taobao: function() {
      return ua.replace(/^.*aliapp\(tb\/([\d.]+).*$/, "$1");
    },
    alipay: function() {
      return ua.replace(/^.*aliapp\(ap\/([\d.]+).*$/, "$1");
    },
    weibo: function() {
      return ua.replace(/^.*weibo__([\d.]+).*$/, "$1");
    },
    iqiyi: function() {
      return ua.replace(/^.*iqiyiversion\/([\d.]+).*$/, "$1");
    },
    dingtalk: function() {
      return ua.replace(/^.*dingtalk\/([\d.]+).*$/, "$1");
    },
    huawei: function() {
      return ua.replace(/^.*version\/([\d.]+).*$/, "$1");
    },
    fit: function() {
      return ua.replace(/^.*fit_([\d.]+).*$/, "$1");
    },
  };
  let version: string = "unknown";
  if (versionRep[browser()]) {
    version = versionRep[browser()]();
  }
  return version;
}
export { ua, system, device, browser, version };
