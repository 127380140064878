/*
 * @Description:
 * @Autor: liuguangkuo
 * @Date: 2019-12-18 14:22:26
 * @LastEditTime: 2020-03-30 10:14:50
 */
/**
 * axios封装
 * 请求拦截、响应拦截、错误统一处理
 */
import Vue from "vue";
import axios from "axios";
import cookie from "vue-cookies";
import qs from "qs";
import * as F from "@/tool/fitTool";
/*
 * vantToast
 */
import { Toast } from "vant";
Vue.use(cookie).use(Toast);

// 创建axios实例
let http = axios.create({ timeout: 1000 * 12 });
// 设置post请求头
http.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded";

/**
 * 请求拦截器
 * 每次请求前，如果存在token则在请求头中携带token
 */
// http.interceptors.request.use(function (config) {
//   // 在发送请求之前做些什么
//   let fitToken = (cookie as any).get("fitToken")
//   if(fitToken){
//     config.headers['token'] = fitToken;
//   }
//   return config;
//   // else{
//   //   http
//   //   .post(
//   //     "/wuAPI/fitDcApi/sptCom/getDcApiSignKeyReq",
//   //     qs.stringify({
//   //       "form.reqUid": F.userid(),
//   //       "form.channel": "dc2"
//   //     })
//   //   )
//   //   .then(res => {
//   //     if(res.data.result == 'Y'){
//   //       (cookie as any).set('fitToken',res.data.entRet.priKey,res.data.entRet.expireTimes)
//   //       config.headers['token'] = fitToken;
//   //     }
//   //     return config;
//   //   })
//   //   .catch(res => {
//   //     Toast("获取token失败");
//   //   });
//   //   return config;
//   // }
// }, function (error) {
//   // 对请求错误做些什么
//   return Promise.reject(error);
// });

export default http;
