
import Vue from "vue";
export default Vue.extend({
  data() {
    return {
      lrtsRedeemCd: "",
      fruitdayRedeemCd: ""
    };
  },
  created() {
    this.lrtsRedeemCd = this.$route.query.lrtsRedeemCd.toString();
    this.fruitdayRedeemCd = this.$route.query.fruitdayRedeemCd.toString();
  },
  methods: {
    /**
     * 复制打开微信
     */
    copyFn(val) {
      var obj = {
        text: val,
        needOpenWexin: "0"
      };
      this.$bridge.NativeBridge("copyAction", obj, 0);
    }
  }
});
