
import Vue from "vue";
import { slider, slideritem } from "vue-concise-slider";
import wxShare from "../../tool/wechat";
import { Toast, Lazyload, Popup, Dialog } from "vant";
let timer: any;
Vue.use(Toast)
  .use(Lazyload)
  .use(Dialog)
  .use(Popup);
let isClick = true;
export default Vue.extend({
  mixins: [wxShare],
  data() {
    return {
      lazyImg: [
        "https://cdn.fitapp.cn/vue/subject/2021-1-1/title_1.png",
        "https://cdn.fitapp.cn/vue/subject/2021-1-1/pic_2.png",
        "https://cdn.fitapp.cn/vue/subject/2021-1-1/title_2.png",
        "https://cdn.fitapp.cn/vue/subject/2021-1-1/title_3.png",
        "https://cdn.fitapp.cn/vue/subject/2021-1-1/privilege-1.png",
        "https://cdn.fitapp.cn/vue/subject/2021-1-1/privilege-2.png",
        "https://cdn.fitapp.cn/vue/subject/2021-1-1/privilege-3.png",
        "https://cdn.fitapp.cn/vue/subject/2021-1-1/btn1_1.png",
        "https://cdn.fitapp.cn/vue/subject/2021-1-1/btn1_2.png",
        "https://cdn.fitapp.cn/vue/subject/2021-1-1/btn4_1.png",
        "https://cdn.fitapp.cn/vue/subject/2021-1-1/btn4_2.png",
        "https://cdn.fitapp.cn/vue/subject/2021-1-1/pic_3.png",
        "https://cdn.fitapp.cn/vue/subject/2021-1-1/pic_4.png",
        "https://cdn.fitapp.cn/vue/subject/2021-1-1/pic_5.png",
        "https://cdn.fitapp.cn/vue/subject/2021-1-1/btn5_1.png",
        "https://cdn.fitapp.cn/vue/subject/2021-1-1/btn5_2.png",
        "https://cdn.fitapp.cn/vue/subject/2021-1-1/pic_6.png"
      ],
      info: {
        trainid: "",
        actuid: "",
        actFlg: "-2",
        entMeda: {
          lstPlan: [],
          planLblId: ""
        },
        lstActGrpMcData: [
          {
            andComId: "",
            comdDesc: "",
            userBuyStatus: "1",
            lstAct0GrpComData: [
              {
                grpTitle: "",
                activityPrice: ""
              }
            ]
          },
          {
            andComId: "",
            comdDesc: "",
            userBuyStatus: "1",
            lstAct0GrpComData: [
              {
                grpTitle: "",
                activityPrice: ""
              }
            ]
          }
        ],
        lstTrainActInfo: [
          {
            periodId: "",
            bprice: "",
            classType: "",
            classTitle: "",
            priceFlg: "",
            trainActId: "",
            getComActHome: {
              userBuyStatus: "1"
            }
          }
        ]
      },
      tabIndex: 0,
      someList: [
        "https://cdn.fitapp.cn/vue/subject/2021-1-1/comment-1.png",
        "https://cdn.fitapp.cn/vue/subject/2021-1-1/comment-2.png",
        "https://cdn.fitapp.cn/vue/subject/2021-1-1/comment-3.png",
        "https://cdn.fitapp.cn/vue/subject/2021-1-1/comment-4.png"
      ],
      options: {
        itemAnimation: true,
        centeredSlides: true,
        loopedSlides: 2,
        slidesToScroll: 1,
        loop: true,
        autoplay: "3000"
      }
    };
  },
  created() {
    this.getPageInfo();
    this.tabSwiper();
    /**
     * 右上角分享初始化
     */
    this.$bridge.setShareInfo({
      title: "🎁新年惊喜｜福利1+1想送给你",
      link: this.$F.getOrigin() + "/subject/2021-1-1",
      imgUrl: "https://cdn.fitapp.cn/vue/subject/2021-1-1/share.png",
      desc: "VIP会员买1送1、减脂营升级大促，点开有喜",
      ctitle: "🎁新年惊喜｜福利1+1想送给你",
      olapflg: "25",
      display: "1",
      shareList: "1,2"
    });
    /**
     * 设置微信分享
     */
    if (this.$B.browser() == "wechat") {
      this.setShare();
    }
  },
  methods: {
    /**
     * 获取基础数据
     */
    getPageInfo() {
      const _this = this;
      _this.$axios
        .post(
          "/wuAPI/fitDcApi/fitH5Act/getGrpActComMcResource",
          _this.$qs.stringify({
            "form.reqUid": "64789-vue",
            "form.userId": _this.$F.userid() == 1 ? "10092" : _this.$F.userid(),
            "form.mbType": _this.$B.system() == "ios" ? "1" : "0",
            "form.channel": "dc2",
            "form.grpType": "2101ACT"
          })
        )
        .then(res => {
          window.console.log(res);
          if (res.data.result == "Y") {
            let entRet = res.data.entRet;
            _this.info = entRet;
          } else {
            Toast("页面加载失败");
          }
        })
        .catch(res => {
          Toast("页面加载失败");
        });
    },
    /**
     * 微信分享
     */
    setShare() {
      const shareInfo = {
        title: `🎁新年惊喜｜福利1+1想送给你`,
        desc: `VIP会员买1送1、减脂营升级大促，点开有喜`,
        link: this.$F.getOrigin() + "/subject/2021-1-1",
        img: "https://cdn.fitapp.cn/vue/subject/2021-1-1/share.png"
      };
      (this as any).wechatShare(shareInfo);
    },
    /**
     * tab
     */
    tabFn(index) {
      clearInterval(timer);
      this.tabIndex = index;
      const tabObj: any = document.querySelectorAll(".tab-item");
      for (let i = 0; i < tabObj.length; i++) {
        tabObj[i].style = "z-index:0";
      }
      tabObj[index].style = "z-index:1";
      this.tabSwiper();
    },
    /**
     * 自动滑动
     */
    tabSwiper() {
      const _this = this;
      timer = setInterval(function() {
        _this.tabIndex = _this.tabIndex + 1;
        if (_this.tabIndex == 3) {
          _this.tabIndex = 0;
        }
        const tabObj: any = document.querySelectorAll(".tab-item");
        for (let i = 0; i < tabObj.length; i++) {
          tabObj[i].style = "z-index:0";
        }
        tabObj[_this.tabIndex].style = "z-index:1";
      }, 3000);
    },
    /**
     * 支付
     */
    payFn(index) {
      const _this = this;
      if (_this.$B.browser() != "fit") {
        _this.goFit();
        return false;
      }
      if (_this.info.actFlg == "-1") {
        Toast("活动未开始");
        return false;
      }
      if (_this.info.actFlg == "-2") {
        Toast("活动已结束");
        return false;
      }
      if (_this.$F.dev() >= 620) {
        const popGoodsInfo = {
          commodityId: _this.info.lstActGrpMcData[index].andComId, //商品id
          callType: "5",
          commodityTitle:
            _this.info.lstActGrpMcData[index].lstAct0GrpComData[0].grpTitle, //商品标题
          commodityComment: _this.info.lstActGrpMcData[index].comdDesc, //商品描述
          comment: _this.info.lstActGrpMcData[index].comdDesc, //商品描述
          price:
            _this.info.lstActGrpMcData[index].lstAct0GrpComData[0]
              .activityPrice, //价格
          commodityType: "3",
          buyComment: _this.info.lstActGrpMcData[index].comdDesc,
          buyTitle:
            _this.info.lstActGrpMcData[index].lstAct0GrpComData[0].grpTitle //商品标题
        };
        let payObj = {
          showApplePay: "0",
          goodsInfo: JSON.stringify(popGoodsInfo),
          cusColor: "#DBB76A",
          androidStyleType: "0",
          callback: "successFn",
          paySort: "0,1",
          showPaySheet: "0"
        };

        if (_this.$F.dev() >= 635 && _this.$B.system() == "ios") {
          payObj.showPaySheet = "0";
          payObj.showApplePay = "1";
          payObj.paySort = "";
        }
        //支付
        _this.$bridge.NativeBridge("payPop", payObj);

        (window as any).successFn = function(msg) {
          msg = JSON.parse(msg);
          if (msg.type == "success") {
            _this.info.lstActGrpMcData[index].userBuyStatus = "2";
            if (_this.$B.system() != "ios") {
              Toast("支付成功");
            }
            if (index == 0) {
              (window as any).gio("track", "web_bnt_click", {
                target_module: "立即开通支付成功",
                target_title: "Fit 新年大促",
                target_id: _this.$F.userid()
              });
            } else {
              (window as any).gio("track", "web_bnt_click", {
                target_module: "减脂营1+1支付成功",
                target_title: "Fit 新年大促",
                target_id: _this.$F.userid()
              });
            }
          }
        };
      } else {
        Toast("请下载最新版app！");
      }
      if (index == 0) {
        (window as any).gio("track", "web_bnt_click", {
          target_module: "立即开通",
          target_title: "Fit 新年大促",
          target_id: _this.$F.userid()
        });
      } else {
        window.console.log(31231231);
        (window as any).gio("track", "web_bnt_click", {
          target_module: "减脂营1+1",
          target_title: "Fit 新年大促",
          target_id: _this.$F.userid()
        });
      }
    },
    /**
     * 进入实物页面购买
     */
    goMall(index) {
      let _this = this;
      if (_this.$B.browser() != "fit") {
        _this.goFit();
        return false;
      }
      if (_this.info.actFlg == "-1") {
        Toast("活动未开始");
        return false;
      }
      if (_this.info.actFlg == "-2") {
        Toast("活动已结束");
        return false;
      }
      if (_this.$F.dev() < 637) {
        Dialog.alert({
          title: "提示",
          message: "当前版本过低，请升级至最新版本后购买！",
          confirmButtonText: "去升级"
        }).then(() => {
          if (_this.$B.system() == "ios") {
            _this.$bridge.NativeBridge("clickDw", "跳转下载");
          } else {
            _this.$openPage.NativeOpenPage("101");
          }
        });
        return false;
      }
      //直接购买
      _this.$openPage.NativeOpenPage(
        "5",
        _this.info.lstActGrpMcData[1].lstAct0GrpComData[0]
      );
    },
    /**
     * 提交订单
     */
    creatOrderFn() {
      //验证
      let _this = this;
      if (_this.$B.browser() != "fit") {
        _this.goFit();
        return false;
      }
      if (_this.info.actFlg == "-1") {
        Toast("活动未开始");
        return false;
      }
      if (_this.info.actFlg == "-2") {
        Toast("活动已结束");
        return false;
      }
      if (isClick) {
        isClick = false;
        //创建订单
        _this.$axios
          .post(
            "/wuAPI/fitDcApi/sptWxH5Xly/modTrainActId4UserReq",
            _this.$qs.stringify({
              "form.channel": "dc2",
              "form.reqUid": _this.$F.userid(),
              "form.strAppointFlg": "0",
              "form.trainActId": _this.info.lstTrainActInfo[0].trainActId,
              "form.periodId": _this.info.lstTrainActInfo[0].periodId,
              "form.bprice": _this.info.lstTrainActInfo[0].bprice,
              "form.classType": _this.info.lstTrainActInfo[0].classType,
              "form.classTitle": _this.info.lstTrainActInfo[0].classTitle,
              "form.payType": "0",
              "form.priceFlg": _this.info.lstTrainActInfo[0].priceFlg,
              "form.phoneType": _this.$B.system() == "ios" ? "1" : "0"
            })
          )
          .then(res => {
            if (res.data.result == "Y") {
              setTimeout(function() {
                _this.trainPay(res.data.entRet);
                _this.info.trainid = res.data.entRet.trainid;
                _this.info.actuid = res.data.entRet.actuid;
              }, 1000);
            }
          })
          .catch(res => {
            Toast("页面加载失败");
            isClick = true;
          });
      }
      (window as any).gio("track", "web_bnt_click", {
        target_module: "细腰马甲线",
        target_title: "Fit 新年大促",
        target_id: _this.$F.userid()
      });
    },
    /**
     * trainPay
     */
    trainPay(res: any) {
      let _this = this;
      var popGoodsInfo = {
        commodityId: _this.info.lstTrainActInfo[0].classType, //商品id
        callType: "2",
        commodityTitle: _this.info.lstTrainActInfo[0].classTitle, //商品标题
        commodityComment: _this.info.lstTrainActInfo[0].classTitle, //商品描述
        comment: _this.info.lstTrainActInfo[0].classTitle, //商品描述
        price: _this.info.lstTrainActInfo[0].bprice, //价格
        commodityType: "3",
        buyComment: "",
        buyTitle: _this.info.lstTrainActInfo[0].classTitle //商品标题
      };
      let color = "FA8C55";
      var payObj = {
        showPaySheet: "1",
        showApplePay: "0",
        goodsInfo: JSON.stringify(popGoodsInfo),
        cusColor: "#DBB76A",
        callback: "successFn",
        androidStyleType: _this.info.lstTrainActInfo[0].trainActId,
        paySort: "0,1"
      };
      if (_this.$F.dev() >= 643) {
        if (_this.$B.system() == "ios") {
          payObj.showPaySheet = "0";
          payObj.showApplePay = "1";
          payObj.paySort = "";
        }
      }
      //支付
      isClick = true;
      _this.$bridge.NativeBridge("payPop", payObj);
      //支付成功运行方法
      (window as any).successFn = function(msg: any) {
        msg = JSON.parse(msg);
        if (msg.type == "success") {
          _this.trainPaySuccess();
          _this.info.lstTrainActInfo[0].getComActHome.userBuyStatus = "2";
          (window as any).gio("track", "web_bnt_click", {
            target_module: "细腰马甲线支付成功",
            target_title: "Fit 新年大促",
            target_id: _this.$F.userid()
          });
        }
      };
    },
    /**
     * 训练营支付成功
     */
    trainPaySuccess() {
      let _this = this;
      _this.$axios
        .post(
          "/wuAPI/fitDcApi/sptWxH5Xly/getTrainActUserOrderId4Resource",
          _this.$qs.stringify({
            "form.reqUid": _this.$F.userid(),
            "form.trainActId": _this.info.trainid,
            "form.channel": "dc2",
            "form.reqFlg": 3,
            "form.actuId": _this.info.actuid,
            "form.genFlg": "2101ACT"
          })
        )
        .then(res => {})
        .catch(res => {
          Toast("页面加载失败");
        });
    },
    /**
     * 打开课程详情
     */
    goTrain(id) {
      if (this.$B.browser() != "fit") {
        this.goFit();
        return false;
      }
      this.$openPage.NativeOpenPage("7", id);
    },
    /**
     * 跳转专题课程
     */
    goMore() {
      if (this.$B.browser() != "fit") {
        this.goFit();
        return false;
      }
      this.$openPage.NativeOpenPage("13", this.info.entMeda.planLblId);
    },
    /**
     * 微信打开活动页面openFit
     */
    goFit() {
      this.$openPage.openFit("1", {
        url: this.$F.getOrigin() + "/subject/2021-1-1"
      });
    }
  },
  components: {
    slider,
    slideritem
  }
});
