
import Vue from "vue";
export default Vue.extend({
  data() {
    return {
      v: ""
    };
  },
  created() {
    this.v = this.$route.query.v.toString();
  }
});
