
import Vue from "vue";
import { Toast, Icon } from "vant";
Vue.use(Icon);
export default Vue.extend({
  data() {
    return {
      imgList: [
        "https://cdn.fitapp.cn/vue/cjsj/group/1v3-p3@3x.jpg",
        "https://cdn.fitapp.cn/vue/cjsj/group/1v3-p4-1@3x.jpg",
        "https://cdn.fitapp.cn/vue/cjsj/group/1v3-p4-2@3x.jpg",
        "https://cdn.fitapp.cn/vue/cjsj/group/1v3-p4-3@3x.jpg",
        "https://cdn.fitapp.cn/vue/cjsj/group/1v3-p5@3x.jpg",
        "https://cdn.fitapp.cn/vue/cjsj/group/1v3-p6@3x.jpg",
        "https://cdn.fitapp.cn/vue/cjsj/group/1v3-p7-1@3x.jpg",
        "https://cdn.fitapp.cn/vue/cjsj/group/1v3-p7-2@3x.jpg"
      ],
      info: {
        isBuy: "0",
        goods: {
          commodity_desc: "",
          commodity_id: "",
          commodity_title: "",
          price: ""
        }
      }
    };
  },
  created() {
    this.getPageInfo();
  },
  methods: {
    /**
     * 获取基础数据
     */
    getPageInfo() {
      const _this = this;
      _this.$axios
        .post(
          "/zhangAPI/SFitWeb/sfit/getGrpActComMcResource",
          _this.$qs.stringify({
            "form.reqUid": "64789-vue",
            "form.userId": _this.$F.userid() == 1 ? "10092" : _this.$F.userid(),
            "form.channel": "dc2",
            "form.phoneType": _this.$B.system() == "ios" ? "1" : "0",
            "form.grpType": "20211223",
            "form.version": _this.$F.dev(1)
          })
        )
        .then(res => {
          if (res.data.result == "Y") {
            _this.info = res.data.entRet;
          } else {
            Toast("页面加载失败");
          }
        })
        .catch(res => {
          Toast("页面加载失败");
        });
    },
    /**
     * 支付
     */
    payFn(index) {
      const _this = this;
      if (_this.info.isBuy == "1") {
        window.location.href = "/cjsj/leagueSuccess";
        return false;
      }
      if (_this.$F.dev() >= 620) {
        const popGoodsInfo = {
          commodityId: _this.info.goods.commodity_id, //商品id
          callType: "2",
          commodityTitle: _this.info.goods.commodity_title, //商品标题
          commodityComment: "", //商品描述
          comment: "", //商品描述
          price: _this.info.goods.price, //价格
          commodityType: "3",
          buyComment: "",
          buyTitle: _this.info.goods.commodity_title //商品标题
        };
        let payObj = {
          showPaySheet: "1",
          showApplePay: "0",
          goodsInfo: JSON.stringify(popGoodsInfo),
          cusColor: "#FFFFFF",
          callback: "successFn",
          androidStyleType: "0",
          paySort: "1,0",
          btnColor: "#4564D6"
        };
        if (_this.$F.dev() >= 635 && _this.$B.system() == "ios") {
          payObj.showPaySheet = "0";
          payObj.showApplePay = "1";
          payObj.paySort = "";
        }
        //支付
        _this.$bridge.NativeBridge("payPop", payObj);
        (window as any).successFn = function(msg) {
          msg = JSON.parse(msg);
          if (msg.type == "success") {
            _this.info.isBuy = "1";
            window.location.href = "/cjsj/leagueSuccess";
          }
        };
      }
      (window as any)._czc.push(["_trackEvent", "超级私教1对3", "立即体验"]);
    }
  }
});
