
import Vue from "vue";
import { Toast, Popup } from "vant";
Vue.use(Toast).use(Popup);
let timer: any = null;
export default Vue.extend({
  data() {
    return {
      joinShowFlg: false,
      testHistoryShowFlg: false,
      d: "00",
      h: "00",
      m: "00",
      s: "00",
      endTime: 0, //倒计时
      info: {
        actUId: "",
        qunCd: "",
        bmeasureFlg: "",
        dietPlanFlg: "",
        physicalJson: "",
        trainPlanDate: "",
        phyId: "",
        wxjfg: "0"
      },
      classtitle: "" //标题
    };
  },
  created() {
    let classtitle = this.$route.query.classtitle as string;
    this.classtitle = classtitle ? classtitle : "超级减脂营";
    this.getPageInfo();
    /**
     * 页面加载设置
     */
    this.$bridge.setBridgeIsShowInfo({
      close: "1",
      pull: "1",
      prev: "1",
      noback: "0",
      slip: "1",
      service: "0"
    });
  },
  mounted() {
    this.leftTimer();
  },
  methods: {
    //获取页面数据
    getPageInfo() {
      let _this = this;
      let actuId = _this.$route.query.actuId; //活动id
      _this.$axios
        .post(
          "/wuAPI/fitDcApi/sptWxH5Jzy/getWxTrainActFlgResource",
          _this.$qs.stringify({
            "form.reqUid": "64789-vue",
            "form.userId": _this.$F.userid(),
            "form.procFlg": 4,
            "form.trainActId": "15",
            "form.actuId": actuId,
            "form.channel": "dc2"
          })
        )
        .then(res => {
          if (res.data.result == "Y") {
            _this.info = res.data.entRet;
            _this.endTime = parseInt(res.data.entRet.leftStartSeconds);
          } else {
            Toast(res.data.entError.errorShowMessage);
          }
        })
        .catch(res => {
          Toast("页面加载失败");
        });
    },
    /**
     * 上传体测数据和修改去价格状态
     */
    upTest(comFlg) {
      let _this = this;
      let actuId = _this.$route.query.actuId; //活动id
      _this.$axios
        .post(
          "/wuAPI/fitDcApi/sptWxH5Jzy/upNcomWebReq",
          _this.$qs.stringify({
            "form.reqUid": "64789-vue",
            "form.userId": _this.$F.userid(),
            "form.distField": actuId,
            "form.upField": comFlg == "FMKT_TACTU_II" ? _this.info.phyId : "",
            "form.comFlg": comFlg,
            "form.channel": "dc2"
          })
        )
        .then(res => {
          if (res.data.result == "Y") {
            if (comFlg == "FMKT_TACTU_II") {
              _this.goTestHistory();
            }
          } else {
            Toast("数据提交失败");
          }
        })
        .catch(res => {
          Toast("页面加载失败");
        });
    },
    /**
     * 倒计时
     */
    leftTimer() {
      let _this = this;
      let obj = (window as any).document.querySelector(".train-code-time");
      timer = setInterval(function() {
        let dhms = _this.$F.countDown(_this.endTime);
        _this.endTime--;
        _this.d = dhms.d;
        _this.h = dhms.h;
        _this.m = dhms.m;
        _this.s = dhms.s;
        if (
          dhms.d == "00" &&
          dhms.h == "00" &&
          dhms.m == "00" &&
          dhms.s == "00"
        ) {
          clearInterval(timer);
          timer = null;
        }
      }, 1000);
    },
    /**
     * 复制打开微信
     */
    copyFn() {
      let _this = this;
      Toast("已复制");
      setTimeout(function() {
        var obj = {
          text: _this.info.qunCd,
          needOpenWexin: "1"
        };
        _this.$bridge.NativeBridge("copyAction", obj, 0);
        _this.joinShowFlg = false;
      }, 1000);
    },
    /**
     * 点击体测
     */
    clickTest() {
      if (this.info.bmeasureFlg == "1") {
        this.goTestHistory();
      } else {
        if (this.info.trainPlanDate) {
          this.testHistoryShowFlg = true;
        } else {
          this.goTest();
        }
      }
    },
    /**
     * 跳转体测历史
     */
    goTestHistory() {
      let obj = {
        json: this.info.physicalJson,
        actid: this.info.actUId
      };
      this.$openPage.NativeOpenPage("15", obj);
      this.testHistoryShowFlg = false;
    },
    /**
     * 跳转到体测
     */
    goTest() {
      this.testHistoryShowFlg = false;
      this.joinShowFlg = false;
      this.$openPage.NativeOpenPage("14", this.info.actUId);
    },
    /**
     * 跳转到食谱
     */
    goDiet() {
      if (this.info.dietPlanFlg != "1") {
        this.$openPage.NativeOpenPage("16", this.info.actUId);
      }
    },
    /**
     * 去加入
     */
    joinShowFn() {
      this.joinShowFlg = true;
      if (this.info.wxjfg == "0") {
        this.info.wxjfg = "1";
        this.upTest("FMKT_TACTU_I");
      }
      if (this.$B.system() == "ios") {
        this.$bridge.NativeBridge("sendNativeMessage", {
          name: "campToJoin"
        });
      }
    }
  }
});
