
import Vue from "vue";
import { Toast, Lazyload } from "vant";
import cookie from "vue-cookies";
import scroll from "vue-seamless-scroll";
Vue.use(Toast).use(Lazyload);
export default Vue.extend({
  data() {
    return {
      leaveShow: false, //挽留弹出
      mallFlg: false, //是否购买
      transitionName: "van-slide-down",
      info: {
        //基础数据
        price: "",
        commodityId: "",
        commodityTitle: "",
        commodityDesc: "",
        entMeda: {}
      },
      imgList: {
        img1: "https://cdn.fitapp.cn/vue/vip/renew/10-13/title-02.png",
        img2: "https://cdn.fitapp.cn/vue/vip/renew/10-21/01.png",
        img3: "https://cdn.fitapp.cn/vue/vip/renew/10-21/02.png",
        img4: "https://cdn.fitapp.cn/vue/vip/renew/10-20/4.png",
        img5: "https://cdn.fitapp.cn/vue/vip/renew/10-20/5.png",
        img6: "https://cdn.fitapp.cn/vue/vip/renew/10-13/title-03.png",
        img7: "https://cdn.fitapp.cn/vue/vip/7-7/0vip_pic_017.png",
        img8: "https://cdn.fitapp.cn/vue/vip/7-7/0vip_title_03.png",
        img9: "https://cdn.fitapp.cn/vue/vip/renew/10-20/1.png"
      },
      commontlist: [
        {
          uaerName: "周*瑜",
          imgUrl: "https://cdn.fitapp.cn/vue/vip/7-7/0vip_avatar_01.png",
          text:
            "不得不说，Fit是慢慢的让你喜欢上他，对于我这种锻炼新手，又想动作简单也可以消耗，这个app的课程很适合我。"
        },
        {
          uaerName: "WW*镧",
          imgUrl: "https://cdn.fitapp.cn/vue/vip/7-7/0vip_avatar_02.png",
          text:
            "健身初级人员非常适合，各种类型可选择，也可以根据自己情况调整难度。"
        },
        {
          uaerName: "婉儿*勇敢",
          imgUrl: "https://cdn.fitapp.cn/vue/vip/7-7/0vip_avatar_03.png",
          text:
            "唯一一个很长很长时间一直坚持用的软件，特别喜欢，教练动作示范特别清晰，课程设计非常好，真的一生推！"
        },
        {
          uaerName: "Jags *e",
          imgUrl: "https://cdn.fitapp.cn/vue/vip/7-7/0vip_avatar_04.png",
          text:
            "这个软件太好用了，适合上班族，没太多时间去锻炼，但是每天总能抽出十几二十分钟的那种，用了一个月之后都说我明显瘦了。"
        }
      ],
      commontlist2: [
        {
          uaerName: "SL* na",
          imgUrl: "https://cdn.fitapp.cn/vue/vip/7-7/0vip_avatar_05.png",
          text:
            "对于想练又没有方法的人来说，特别是定制课程，我觉得真的很有用，相当于大半个健身教练了！"
        },
        {
          uaerName: "郭*杰",
          imgUrl: "https://cdn.fitapp.cn/vue/vip/7-7/0vip_avatar_06.png",
          text:
            "有初级入门课程，制定完整的计划，帮助我塑造完美曲线，感谢这款产品，让我有拉动力。"
        },
        {
          uaerName: "芳妮宝贝",
          imgUrl: "https://cdn.fitapp.cn/vue/vip/7-7/0vip_avatar_07.png",
          text:
            "真的很不错 希望自己接下去能够坚持，坚持天天锻炼。每天很贴心的被提醒，感觉非常温馨。 总之，好评好评，希望一直这样。"
        },
        {
          uaerName: "qqq*kk",
          imgUrl: "https://cdn.fitapp.cn/vue/vip/7-7/0vip_avatar_08.png",
          text:
            "录制的课程里教练，声音，背景音乐，都比较喜欢，没有那么多花哨的东西。个人觉得比k**p好用！"
        }
      ]
    };
  },
  computed: {
    /**
     * 定制训练饮食计划
     */
    optionLeft() {
      return {
        step: 0.4,
        direction: 2,
        limitMoveNum: 2,
        hoverStop: false,
        openTouch: false
      };
    },
    optionLeft2() {
      return {
        step: 0.5,
        direction: 2,
        limitMoveNum: 2,
        hoverStop: false,
        openTouch: false
      };
    },
    /**
     * 中奖信息滚动
     */
    optionSingleHeightTime() {
      return {
        singleHeight: 0.46,
        waitTime: 2500,
        openTouch: false,
        // switchSingleStep:100,
        isSingleRemUnit: true
      };
    }
  },
  created() {
    const _this = this;
    _this.getPageInfo();
    /**
     * 页面加载设置
     */
    _this.$bridge.setBridgeIsShowInfo({
      close: "1",
      pull: "1",
      prev: "1",
      noback: "0",
      slip: "1",
      service: "0"
    });
    /**接受客户端数据 */
    (window as any).sendWebMessage = function(val) {
      let obj = JSON.parse(val);
      if (obj.event == "click" && obj.name == "back") {
        window.console.log(
          !(cookie as any).get("backRenewFlg"),
          !_this.mallFlg,
          obj
        );
        if (!(cookie as any).get("backRenewFlg") && !_this.mallFlg) {
          _this.animate("van-slide-down");
          (cookie as any).set("backRenewFlg", false, "1d");
        } else {
          var openObj = {};
          _this.back();
        }
      }
    };
  },
  methods: {
    /**
     * 获取基础数据
     */
    getPageInfo() {
      const _this = this;
      _this.$axios
        .post(
          "/wuAPI/fitDcApi/fitH5Act/getActZeroReBuyResource",
          _this.$qs.stringify({
            "form.reqUid": _this.$F.userid(),
            "form.mbType": _this.$B.system() == "ios" ? "1" : "0"
          })
        )
        .then(res => {
          window.console.log(res);
          if (res.data.result == "Y") {
            this.info = res.data.entRet;
          } else {
            window.location.href = "/vip/vipMall";
          }
        })
        .catch(res => {
          Toast("页面加载失败");
        });
    },
    /**
     * 打开客服
     */
    openCustomService() {
      this.$openPage.NativeOpenPage("3", "kefu");
    },
    /**
     * 支付
     */
    payFn() {
      const _this = this;
      if (_this.$F.dev() >= 620) {
        const popGoodsInfo = {
          commodityId: _this.info.commodityId, //商品id
          callType: "5",
          commodityTitle: _this.info.commodityTitle, //商品标题
          commodityComment: _this.info.commodityDesc, //商品描述
          comment: _this.info.commodityDesc, //商品描述
          price: _this.info.price, //价格
          commodityType: "3",
          buyComment: _this.info.commodityDesc,
          buyTitle: _this.info.commodityTitle //商品标题
        };
        let payObj = {
          showApplePay: "0",
          goodsInfo: JSON.stringify(popGoodsInfo),
          cusColor: "#DBB76A",
          androidStyleType: "0",
          callback: "successFn",
          paySort: "0,1",
          showPaySheet: "0"
        };

        if (_this.$F.dev() >= 635 && _this.$B.system() == "ios") {
          payObj.showPaySheet = "0";
          payObj.showApplePay = "1";
          payObj.paySort = "";
        }
        //支付
        _this.$bridge.NativeBridge("payPop", payObj);
        (window as any).successFn = function(msg) {
          msg = JSON.parse(msg);
          if (msg.type == "success") {
            _this.mallFlg = true;
            if (_this.$B.system() != "ios") {
              Toast("支付成功");
            }
          }
        };
      } else {
        Toast("请下载最新版app！");
      }
    },
    /**
     * 挽留弹出动画
     */
    animate(transitionName) {
      this.leaveShow = true;
      this.transitionName = transitionName;
    },
    /**
     * 返回页面
     */
    back() {
      this.$bridge.NativeBridge("blackAction", {});
    },
    /**
     * 打开课程详情
     */
    goTrain(id) {
      //   if (this.$B.browser() != "fit") {
      //     this.goFit();
      //     return false;
      //   }
      this.$openPage.NativeOpenPage("7", id);
    },
    /**
     * 打开课程库
     */
    goTrainList() {
      //   if (this.$B.browser() != "fit") {
      //     this.goFit();
      //     return false;
      //   }
      this.$openPage.NativeOpenPage("6", "8");
    }
  }
});
