/*
 * @Description:
 * @Autor: liuguangkuo
 * @Date: 2019-12-11 17:31:42
 * @LastEditTime: 2021-05-06 15:12:25
 */
import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

// 引入其他路由文件
import subjectRouter from "./subject";
import staticRouter from "./static";
import fatRouter from "./fat";
import trainRouter from "./train";
import vipRouter from "./vip";
import question from "./question";
import ali from "./ali"; //支付宝
import cjsj from "./cjsj"; //超级私教-教练端
import newuser from "./newuser";
import superCoach from "./superCoach"; //超级私教-用户

let routes = [
  {
    path: "/",
    name: "index",
    component: require("@/views/index").default,
    meta: {
      title: "Fit健身"
    }
  },
  {
    path: "/demo",
    name: "home",
    component: Home
  },
  ...subjectRouter,
  ...staticRouter,
  ...fatRouter,
  ...trainRouter,
  ...vipRouter,
  ...question,
  ...ali,
  ...cjsj,
  ...newuser,
  ...superCoach
];

const router = new VueRouter({
  mode: "history",
  base: "/",
  routes
});

export default router;
