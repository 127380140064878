
import Vue from "vue";
import { Toast } from "vant";
Vue.use(Toast);
export default Vue.extend({
  data() {
    return {
      trainid: "4", //活动id
      info: {
        //基础数据
        classTitle: "",
        periodNum: "",
        cntTxt: ""
      }
    };
  },
  created() {
    this.trainid = this.$route.query.trainid + "";
    this.getPageInfo();
    /**
     * 页面加载设置
     */
    this.$bridge.setBridgeIsShowInfo({
      close: "1",
      pull: "1",
      prev: "1",
      noback: "0",
      slip: "1",
      service: "0"
    });
  },
  methods: {
    //获取页面数据
    getPageInfo() {
      let _this = this;
      _this.$axios
        .post(
          "/wuAPI/fitDcApi/sptWxH5Xly/getTrainActUserOrderId4Resource",
          _this.$qs.stringify({
            "form.reqUid": _this.$F.userid(),
            "form.trainActId": _this.$route.query.trainid,
            "form.channel": "dc2",
            "form.state": 3,
            "form.reqFlg": 0,
            "form.modTrainActUFlg": 1,
            "form.tactuId": _this.$route.query.tactuid,
            "form.actuId": _this.$route.query.actuid,
            "form.orderNum": _this.$route.query.orderid,
            "form.phoneType": _this.$B.system() == "ios" ? "1" : "0"
          })
        )
        .then(res => {
          window.console.log(res);
          _this.info = res.data.entRet;
        })
        .catch(res => {
          Toast("页面加载失败");
        });
    },
    /**
     * 跳转填写手机号页面
     */
    goTrainTel() {
      window.location.href =
        "/train/trainTel?trainid=" +
        this.$route.query.trainid +
        "&actuid=" +
        this.$route.query.actuid +
        "&classtitle=" +
        this.info.classTitle +
        "&isView=1";
    }
  }
});
