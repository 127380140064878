
import Vue from "vue";
import { Cell, CellGroup, Button } from "vant";
Vue.use(Cell)
  .use(CellGroup)
  .use(Button);
export default Vue.extend({
  created() {
    /**
     * 右上角分享初始化
     */
    this.$bridge.setShareInfo({
      title: "21天零负担科学减脂！开始你的蜕变第一步丨 Fit超级减脂营",
      link: "https://www.fitapp.com.cn/wx/2017Fat?id=15",
      imgUrl:
        "https://media.fitapp.cn/fit/fitweb/trainAct/190814/15/15ShareJsonUrlImg.jpg",
      desc: "轻松减脂零负担",
      ctitle: "来这里，一定瘦！丨 超级减脂营",
      weiboTitle: "来这里，轻松瘦！报名立即获取教练定制减脂方案@FitApp",
      weiboImg:
        "https://media.fitapp.cn/fit/fitweb/trainAct/190814/15/15ShareJsonWeiboUrl.jpg",
      olapflg: "25",
      display: "1"
    });
    /**
     * 页面加载设置
     */
    this.$bridge.setBridgeIsShowInfo({
      close: "1",
      pull: "1",
      prev: "1",
      noback: "0",
      slip: "1",
      service: "0"
    });
  },
  methods: {
    /**
     * 打开客户端页面
     */
    openPage(e: any) {
      const page = e.target.dataset.page;
      const val = e.target.dataset.val || "";
      this.$openPage.NativeOpenPage(page, val);
    },
    /**
     * 分享到
     */
    share(e: any) {
      const shareInfo = {
        type: e.target.dataset.type,
        link:
          "https://www.fitapp.com.cn/inviter/cardCode?userid=<%=userid%>&isapp=1",
        title: "使用我的邀请码领取Fit会员，一起免费健身+7天！",
        imgUrl:
          "https://www.fitapp.com.cn/images/inviter/base/img-share@2x.png?v1",
        desc: "随时随地Fit一下， 身材更有型",
        ctitle: "使用我的邀请码领取Fit会员，一起免费健身+7天！",
        weiboTitle:
          "使用我的邀请码领取Fit会员，一起免费健身+7天！ @FitApp" +
          "https://www.fitapp.com.cn/inviter/cardCode?userid=<%=userid%>",
        weiboImg:
          "https://www.fitapp.com.cn/images/inviter/base/img-share-weibo.png",
        olapflg: "32",
        isphoto: "1"
      };
      this.$bridge.NativeBridge("webViewShare", shareInfo);
    },
    /**
     * 支付
     * type: 0阿里，1微信 ,2苹果，3京东，4华为
      var payInfo = {
        comment : '虚拟商品',//商品描述
        commodityId : 'com.sportq.FitFit001', //商品id
        commodityTitle : '减脂入门套装A', //商品标题
        price : '88', //价格
        energyVal : '880', //能量值
        url : url, //成功后跳转的页面地址
        productType : 1 //商品类型（1:商品，2:能量）
        commodityType ： 商品类型（0：普通，1：推荐，2：超值，3：0元购活动）
        source : 渠道来源（1：减脂营，2：腹肌班，3:0元购会员 4：瘦腿班）
        callback : callback 支付后执行的方法名,客户端传会msg参数，值为'{"type":"success","orderid":"1312312312313"}'
      }
     */
    pay(e: any) {
      const payInfo = {
        comment: "虚拟商品",
        commodityId: "com.sportq.FitCampActId15_1",
        commodityTitle: "14天全身减脂计划-基础型 共1期",
        energyVal: "0",
        price: "588.10",
        productType: "2",
        source: "1",
        type: e.target.dataset.type,
        url:
          "https://www.dev.fitapp.cn/h5/2017FatOrderDetails?tactuid=25287&trainid=15&actuId=21648"
      };
      this.$bridge.NativeBridge("fitPay", payInfo);
    },
    /**
   * 弹出支付
   * showApplePay 是否显示苹果支付
      goodsInfo 商品信息
      var popGoodsInfo = {
        commodityId : '', //商品id
        callType : '2',  调用类型（0：能量，1：商城,2:减脂营，3：会员，4：小程序，5:0元购邀请，6：大师课程，7:F币 10:训练营续，8:自动续费1个月，9：自动续费3个月，10:自动续费1年
        commodityTitle : '', //商品标题
        commodityComment : '',//商品描述
        comment : '',//商品描述
        price : '1', //价格
        commodityType : '3',  商品类型（0：普通，1：推荐，2：超值，3：0元购活动）
        buyComment : '', //弹窗显示商品描述
        buyTitle :  //弹窗显示商品标题
        isRenew : '0', 0：不续费（默认不续费），1:续费
      }
      cusColor（ios端，bottom背景色，价格文字颜色，支付方式按钮颜色）#fff
      androidStyleType 安卓样式类型 0：0元购，15：减脂营，14维秘美腿班、4细腰马甲线、12英雄腹肌
      paySort 0支付宝，1:微信，显示多少支付方式且按顺序排序，'0,1'
      btnColor 支付按钮字体颜色 #fff
      callback 支付后执行的方法名,客户端传会msg参数，值为'{"type":"success","orderid":"1312312312313"}'
   */
    payPop() {
      const popGoodsInfo = {
        commodityId: "com.sportq.FitCampActId15_1", //商品id
        callType: "2",
        commodityTitle: "14天全身减脂计划-基础型 共1期", //商品标题
        commodityComment: "虚拟商品", //商品描述
        comment: "虚拟商品", //商品描述
        price: "588.10", //价格
        commodityType: "3",
        buyComment: "我是提示",
        buyTitle: "14天全身减脂计划-基础型 共1期" //商品标题
      };
      const payObj = {
        showApplePay: "0",
        goodsInfo: JSON.stringify(popGoodsInfo),
        cusColor: "#FE533B",
        callback: function(msg: any) {
          window.console.log(msg);
        },
        androidStyleType: "15",
        paySort: "1,0"
      };
      this.$bridge.NativeBridge("payPop", payObj);
    }
  }
});
