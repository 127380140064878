
import Vue from "vue";
import { slider, slideritem } from "vue-concise-slider";
import { Lazyload } from "vant";
Vue.use(Lazyload);
export default Vue.extend({
  data() {
    return {
      imgList: [
        "https://cdn.fitapp.cn/vue/subject/cjsj/report1@2x.jpg",
        "https://cdn.fitapp.cn/vue/subject/cjsj/report2@2x.jpg",
        "https://cdn.fitapp.cn/vue/subject/cjsj/report3@2x.jpg",
        "https://cdn.fitapp.cn/vue/subject/cjsj/report4@2x.jpg",
        "https://cdn.fitapp.cn/vue/subject/cjsj/report5@2x.jpg",
        "https://cdn.fitapp.cn/vue/subject/cjsj/report6@2x.jpg",
        "https://cdn.fitapp.cn/vue/subject/cjsj/report7@2x.jpg"
      ]
    };
  }
});
