
import Vue from "vue";
import { slider, slideritem } from "vue-concise-slider";
import { Lazyload } from "vant";
Vue.use(Lazyload);
export default Vue.extend({
  data() {
    return {
      imgList: [
        "https://cdn.fitapp.cn/vue/superCoach/home_img_class_guide_bg01.png",
        "https://cdn.fitapp.cn/vue/superCoach/home_img_class_guide_bg02.png",
        "https://cdn.fitapp.cn/vue/superCoach/home_img_class_guide_bg03.png",
        "https://cdn.fitapp.cn/vue/superCoach/home_img_class_guide_bg04.png"
      ]
    };
  }
});
