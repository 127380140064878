/*
 * @Description:
 * @Autor: liuguangkuo
 * @Date: 2019-12-13 16:32:22
 * @LastEditTime: 2021-06-23 15:25:16
 */
import { ua, version } from "./browser";
/**
 * 获取Fit版本
 * 例:5.2.2 = 522
 * 例:6.2 = 620
 */
function dev(type = 0): any {
  if (type == 1) {
    return version(); //返回6.3.0格式
  } else {
    let dev: number = parseInt(version().replace(/\./g, ""));
    return dev < 100 ? dev * 10 : dev; //返回 630
  }
}

/**
 *  获取userid
 */
function userid(): number {
  let userid = parseInt(ua.replace(/^.*fituid\/([\d.]+).*$/, "$1"));
  return userid ? userid : 1;
  //   return 35;
}

/**
 *  获取sex
 *  0 : 男
 *  1 : 女
 */
function sex(): number {
  let sex = parseInt(ua.replace(/^.*fitsex\/([\d.]+).*$/, "$1"));
  return sex;
}

/**
 *  获取Fit包下载渠道
 *
 */
function channel(): string {
  let fitChannel = ua.replace(/^.*fitchannel\/([\w.]+).*$/, "$1");
  return fitChannel;
}

/**
 *  获取Fit包下载渠道
 *
 */
function getTourist(): string {
  let isTourist = ua.replace(/^.*istourist\/([\w.]+).*$/, "$1");
  return isTourist;
}

/**
 * 倒计时
 */
function countDown(endTime: number): object {
  let days: string = (endTime / 60 / 60 / 24).toString(); //计算剩余的天数
  let hours: string = ((endTime / 60 / 60) % 24).toString(); //计算剩余的小时
  let minutes: string = ((endTime / 60) % 60).toString(); //计算剩余的分钟
  let seconds: string = (endTime % 60).toString(); //计算剩余的秒数
  return {
    d: checkTime(parseInt(days)),
    h: checkTime(parseInt(hours)),
    m: checkTime(parseInt(minutes)),
    s: checkTime(parseInt(seconds))
  };
}
function checkTime(i: number): string {
  //将0-9的数字前面加上0，例1变为01
  let result = "0";
  if (i < 10) {
    result += i;
  } else {
    result = i + "";
  }
  return result;
}

/**
 * 获取当前页面域名地址
 */
function getOrigin() {
  return window.location.origin;
}
export { dev, userid, sex, channel, countDown, getOrigin, getTourist };
