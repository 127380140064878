/*
 * @Description:
 * @Autor: liuguangkuo
 * @Date: 2019-12-30 16:54:41
 * @LastEditTime: 2021-12-23 18:02:21
 */
import * as B from "../../tool/browser";
import * as F from "../../tool/fitTool";
import * as Nb from "./index";

const system = B.system();
/**
 * 跳转到客户端页面
 * page : string 打开的页面
 * value : string (可以是链接，可以是字符)
 * 1 : 我的会员
 * 2 ：直播页面
 * 3 : 客服
 * 4 : 通知
 * 5 : 0元购+实物跳转订单
 * 6 : 课程库
 * 7 : 课程详情
 * 8 : IOS实物购买，购买成功后跳会到指定页面的支付方法
 * 9: 大师课程
 * 10 我的-使用兑换码
 * 11 定制计划
 * 12 定制计划完善信息页面
 * 13 课程专题
 * 14 体测
 * 15 体测结果
 * 16 生成饮食方案
 * 17 计划-金牌服务
 * 100 :第三方app-抖音
 * 101 ：打开应用市场
 * 102 ：第三方url 有跳转App Store或者其他app的情况
 * 103 ：跳转系统浏览器
 * 104 ：跳转系统邮箱
 * 105 ：跳转系统电话
 * 106 ：跳转系统短信
 * 107 ：微信小程序
 * 108 ：支付宝小程序
 * 109 : 微信
 */
function NativeOpenPage(page: string, value?: any) {
  let param: object = {};
  switch (page) {
    /**
     * 广告跳转客户端页面
     */
    case "0":
      if (system == "ios") {
        param = value;
      } else if (system == "android") {
        param = {
          jsonType: "1",
          jumpJson: value
        };
      }
      Nb.NativeBridge("openPage", param, 0);
      break;
    /**
     * 我的会员
     */
    case "1":
      if (system == "ios") {
        param = {
          page: "MineMediator.gotoVIPPage:comFrom:",
          params: {
            "0": false,
            "1": "$nil"
          }
        };
      } else if (system == "android") {
        param = {
          jsonType: "1",
          jumpJson: {
            page: "com.sportq.fit.fitmoudle.activity.VipCenterActivity"
          }
        };
      }
      Nb.NativeBridge("openPage", param, 0);
      break;

    /**
     * 跳转直播页面
     */
    case "2":
      if (system == "ios") {
        if (F.dev() >= 636) {
          param = {
            page: "NewCommonMediator.gotoLivePageWithUrl:title:coachName:",
            params: {
              "0": value ? value.split("~~~")[1] : "", //直播链接
              "1": value ? value.split("~~~")[0] : "", //直播标题
              "2": value ? value.split("~~~")[2] : "" //教练
            }
          };
        } else {
          param = {
            page: "NewCommonMediator.gotoLivePageWithUrl:title:",
            params: {
              "0": value ? value.split("~~~")[1] : "", //直播链接
              "1": value ? value.split("~~~")[0] : "" //直播标题
            }
          };
        }
      } else if (system == "android") {
        param = {
          jsonType: "1",
          jumpJson: {
            page: "com.sportq.fit.fitmoudle.activity.FitLiveActivity",
            "live.url": value ? value.split("~~~")[1] : "", //直播链接,
            "live.title": value ? value.split("~~~")[0] : "", //直播标题
            "live.coach": value ? value.split("~~~")[2] : "" //教练
          }
        };
      }
      Nb.NativeBridge("openPage", param, 0);
      break;

    /**
     * 跳转客服
     * 销售组 : xiaoshou
     * 客服组 : kefu
     */
    case "3":
      value = value ? value : "kefu"; //默认客服
      if (system == "ios") {
        param = {
          page: "CommonMediator.gotoCustomServicePage:comeFromWeb:",
          params: {
            "0": "$nil",
            "1": value == "kefu" ? false : true
          }
        };
        Nb.NativeBridge("openPage", param, 0);
      } else if (system == "android") {
        param = {
          jsonType: "1",
          jumpJson: {
            page: "customService",
            "service.group": value
          }
        };
        // (window as any).Fitapp.openPage(JSON.stringify(param));
        Nb.NativeBridge("openPage", param, 0);
      }
      break;

    /**
     * 跳转通知
     */
    case "4":
      if (system == "ios") {
        param = {
          page: "MySettingMediator.pushToPushNotificationPage",
          params: {}
        };
      } else if (system == "android") {
        param = {
          jsonType: "1",
          jumpJson: {
            page: "systemNotificationSetting"
          }
        };
      }
      Nb.NativeBridge("openPage", param, 0);
      break;

    /**
     * 0元购+实物跳转订单
     */
    case "5":
      if (system == "ios") {
        param = {
          page: "MyOrderMediator.gotoFillOrderFromShopDetailWithOrderStr:",
          params: {
            "0": value
          }
        };
      } else if (system == "android") {
        param = {
          jsonType: "1",
          jumpJson: {
            page:
              "com.sportq.fit.fitmoudle13.shop.activity.MineVipOrderPaymentActivity",
            "key.data": value
          }
        };
      }
      Nb.NativeBridge("openPage", param, 0);
      break;

    /**
     * 课程库
     */
    case "6":
      if (system == "ios") {
        param = {
          page: "TrainingMediator.pushToTrainFilterPage:",
          params: {
            "0": value
          }
        };
      } else if (system == "android") {
        param = {
          jsonType: "1",
          jumpJson: {
            page: "com.sportq.fit.fitmoudle8.activity.AllCoursesActivity",
            "init.code": value
          }
        };
      }
      Nb.NativeBridge("openPage", param, 0);
      break;

    /**
     * 课程详情
     */
    case "7":
      if (system == "ios") {
        param = {
          page: "TrainingMediator.pushToTrainInfoPage:trainType:",
          params: {
            "0": value,
            "1": "0"
          }
        };
      } else if (system == "android") {
        param = {
          jsonType: "1",
          jumpJson: {
            page:
              "com.sportq.fit.fitmoudle8.activity.Find04GenTrainInfoActivity",
            "plan.id": value,
            "single.type": "0"
          }
        };
      }
      Nb.NativeBridge("openPage", param, 0);
      break;

    /**
     * 0元购+实物跳转到活动页面
     */
    case "8":
      if (system == "ios") {
        param = {
          page: "MyOrderMediator.gotoFillOrderFromActivityWithOrderStr:",
          params: {
            "0": value
          }
        };
      } else if (system == "android") {
        param = {
          jsonType: "1",
          jumpJson: {
            page:
              "com.sportq.fit.fitmoudle13.shop.activity.MineVipOrderPaymentActivity",
            "key.data": value
          }
        };
      }
      Nb.NativeBridge("openPage", param, 0);
      break;

    /**
        大师课堂
     */
    case "9":
      if (system == "ios") {
        param = {
          page: "PerLessonMediator.gotoPerLessonListPage",
          params: {
            "0": "$nil"
          }
        };
      } else if (system == "android") {
        param = {
          jsonType: "1",
          jumpJson: {
            page: "com.sportq.fit.fitmoudle5.activity.MasterClassListActivity"
          }
        };
      }
      Nb.NativeBridge("openPage", param, 0);
      break;

    /**
        我的-兑换码
     */
    case "10":
      window.console.log(312312312);
      if (system == "ios") {
        param = {
          page: "MineMediator.gotoEnergyExchangePage",
          params: {
            "0": "$nil"
          }
        };
      } else if (system == "android") {
        param = {
          jsonType: "1",
          jumpJson: {
            page:
              "com.sportq.fit.fitmoudle9.energy.activity.EnergyInvitCodeActivity",
            "from.type": "2"
          }
        };
      }
      Nb.NativeBridge("openPage", param, 0);
      break;

    /**
        定制计划
     */
    case "11":
      if (system == "ios") {
        param = {
          page: "CommonMediator.gotoLink:detailIds:",
          params: {
            "0": "3",
            "1": "$nil"
          }
        };
      } else if (system == "android") {
        param = {
          jsonType: "1",
          jumpJson: {
            page: "com.sportq.fit.business.NavMainActivity",
            childIndex: "1"
          }
        };
      }
      Nb.NativeBridge("openPage", param, 0);
      break;

    /**
        定制计划完善信息
     */
    case "12":
      if (system == "ios") {
        param = {
          page: "PlanMediator.pushToNoDateCusPlanBMIPage",
          params: {}
        };
      } else if (system == "android") {
        param = {
          jsonType: "1",
          jumpJson: {
            page:
              "com.sportq.fit.fitmoudle7.customize.activity.PerfectInfoToCustomizedActivity",
            "customized.type": value //(type：0:新人体验营报名成功定制  1:正常定制（默认）)
          }
        };
      }
      Nb.NativeBridge("openPage", param, 0);
      break;

    /**
        课程专题
     */
    case "13":
      if (system == "ios") {
        param = {
          page: "HomeDiscoverMediator.gotoCourseTopicDetailPageWithTopicId:",
          params: {
            "0": value
          }
        };
      } else if (system == "android") {
        param = {
          jsonType: "1",
          jumpJson: {
            page:
              "com.sportq.fit.business.find.activity.FindSpecialDetailActivity",
            "selected.id": value //课程专题id
          }
        };
      }
      Nb.NativeBridge("openPage", param, 0);
      break;

    /**
        体测
     */
    case "14":
      if (system == "ios") {
        param = {
          page: "FitnessTestingMediator.goToStartFitnessTestingPageWithActUid:",
          params: {
            "0": value
          }
        };
      } else if (system == "android") {
        param = {
          jsonType: "1",
          jumpJson: {
            page:
              "com.sportq.fit.fitmoudle10.organize.physical_fitness.FitnessTestPreviewActivity",
            "act.uid": value
          }
        };
      }
      Nb.NativeBridge("openPage", param, 0);
      break;

    /**
        体测结果
     */
    case "15":
      if (system == "ios") {
        param = {
          page:
            "FitnessTestingMediator.gotoTestingResultPage:reformer:jsonData:actUid:",
          params: {
            "0": false,
            "1": "$nil",
            "2": value.json,
            "3": value.actid
          }
        };
      } else if (system == "android") {
        param = {
          jsonType: "1",
          jumpJson: {
            page:
              "com.sportq.fit.fitmoudle10.organize.physical_fitness.FitnessTestResultActivity",
            "data.json": value.json, //体测结果数据
            "is.show.test.again.name": value.actid
          }
        };
      }
      Nb.NativeBridge("openPage", param, 0);
      break;

    /**
        生成饮食
     */
    case "16":
      if (system == "ios") {
        param = {
          page:
            "TrainingCampMediator.pushToCompleteInfoPageWithActUid:fatCampId:dateS:isToday:",
          params: {
            "0": value,
            "1": "",
            "2": "",
            "3": true
          }
        };
      } else if (system == "android") {
        param = {
          jsonType: "1",
          jumpJson: {
            page:
              "com.sportq.fit.fitmoudle7.customize.activity.PerfectDietToCampActivity",
            "act.uid": value
          }
        };
      }
      Nb.NativeBridge("openPage", param, 0);
      break;

    /**
        计划-金牌服务
     */
    case "17":
      if (system == "ios") {
        param = {
          page: "LoginSwiftMediator.switchOverMainController:planInt:",
          params: {
            "0": 1,
            "1": 1
          }
        };
      } else if (system == "android") {
        param = {
          jsonType: "1",
          jumpJson: {
            page: "com.sportq.fit.business.NavMainActivity",
            childIndex: "1.1"
          }
        };
      }
      Nb.NativeBridge("openPage", param, 0);
      break;

    /**
     * 打开第三方app
     */
    case "100":
      if (system == "ios") {
        param = {
          page: "NewCommonMediator.openThirdAppWithUrlS:schemeStr:",
          params: {
            "0": value,
            "1": "1142110895"
          }
        };
      } else if (system == "android") {
        param = {
          jsonType: "1",
          jumpJson: {
            page: "openApp",
            url: value,
            appPackageName: "com.ss.android.ugc.aweme"
          }
        };
      }
      Nb.NativeBridge("openPage", param, 0);
      break;

    /**
     * 打开应用市场
     * ios : appStore
     * 安卓：可指定市场
     */
    case "101":
      if (system == "ios") {
        param = {
          page: "NewCommonMediator.openThirdAppWithUrlS:schemeStr:",
          params: {
            "0":
              "https://itunes.apple.com/cn/app/qu-dong-zhong-xin-fa-xian/id1102364578?mt=8", //appStore地址
            "1": ""
          }
        };
      } else if (system == "android") {
        param = {
          jsonType: "1",
          jumpJson: {
            page: "openStore",
            marketPackageName: "", //应用市场包名
            appPackageName: "com.sportq.fit" //指定应用包名
          }
        };
      }
      Nb.NativeBridge("openPage", param, 0);
      break;

    /**
     * 第三方url 有跳转App Store或者其他app的情况
     */
    case "102":
      if (system == "ios") {
        param = {
          page: "NewCommonMediator.pushToWebPageWithNeedLimit:linkUrl:",
          params: {
            "0": "YES",
            "1": value //第三方网页链接
          }
        };
      } else if (system == "android") {
        param = {
          jsonType: "1",
          jumpJson: {
            page: "thirdUrl",
            url: value, //第三方网页链接
            isLimit: "false"
          }
        };
      }
      Nb.NativeBridge("openPage", param, 0);
      break;

    /**
     * 跳转系统浏览器
     */
    case "103":
      if (system == "ios") {
        param = {
          page: "NewCommonMediator.openSFSafariWithLinkUrl:",
          params: {
            "0": value //第三方网页链接
          }
        };
      } else if (system == "android") {
        param = {
          jsonType: "1",
          jumpJson: {
            page: "openApp",
            url: value //第三方网页链接
          }
        };
      }
      Nb.NativeBridge("openPage", param, 0);
      break;

    /**
     * 跳转系统邮箱
     */
    case "104":
      if (system == "ios") {
        param = {
          page: "NewCommonMediator.openThirdAppWithUrlS:schemeStr:",
          params: {
            "0": "mailto://" + value, //邮箱
            "1": ""
          }
        };
      } else if (system == "android") {
        param = {
          jsonType: "1",
          jumpJson: {
            page: "openSystem",
            action: "android.intent.action.SENDTO",
            value: "mailto:" + value //邮箱
          }
        };
      }
      Nb.NativeBridge("openPage", param, 0);
      break;

    /**
     * 跳转系统电话
     */
    case "105":
      if (system == "ios") {
        param = {
          page: "NewCommonMediator.openThirdAppWithUrlS:schemeStr:",
          params: {
            "0": "tel://" + value, //电话
            "1": ""
          }
        };
      } else if (system == "android") {
        param = {
          jsonType: "1",
          jumpJson: {
            page: "openSystem",
            action: "android.intent.action.DIAL",
            value: "tel:" + value //电话
          }
        };
      }
      Nb.NativeBridge("openPage", param, 0);
      break;

    /**
     * 跳转系统短信
     */
    case "106":
      if (system == "ios") {
        param = {
          page: "NewCommonMediator.openThirdAppWithUrlS:schemeStr:",
          params: {
            "0": "sms://" + value, //短信
            "1": ""
          }
        };
      } else if (system == "android") {
        param = {
          jsonType: "1",
          jumpJson: {
            page: "openSystem",
            action: "android.intent.action.SENDTO",
            value: "smsto:" + value //短信
          }
        };
      }
      Nb.NativeBridge("openPage", param, 0);
      break;

    /**
     * 跳转微信小程序
     */
    case "107":
      if (system == "ios") {
        param = {
          page: "NewCommonMediator.openWeChatMiniProgWithIdStr:pathStr:",
          params: {
            "0": value ? value.split("~~~")[0] : "", //小程序id
            "1": value ? value.split("~~~")[1] : "" //需要打开的页面，不传默认打开首页
          }
        };
      } else if (system == "android") {
        param = {
          jsonType: "1",
          jumpJson: {
            page: "openWechatApplet",
            wx_applet_id: value ? value.split("~~~")[0] : "", //小程序id
            path: value ? value.split("~~~")[1] : "" //需要打开的页面，不传默认打开首页
          }
        };
      }
      window.console.log(param);
      Nb.NativeBridge("openPage", param, 0);
      break;

    /**
     * 跳转支付宝小程序
     */
    case "108":
      if (system == "ios") {
        param = {
          page: "NewCommonMediator.openAliMiniProgWithLinkUrl:schemeStr:",
          params: {
            "0": value, //支付宝链接
            "1": "alipays://"
          }
        };
      } else if (system == "android") {
        param = {
          jsonType: "1",
          jumpJson: {
            page: "openApp",
            url: value //短信
          }
        };
      }
      Nb.NativeBridge("openPage", param, 0);
      break;

    /**
     * 打开微信
     */
    case "109":
      if (system == "ios") {
        param = {
          page: "NewCommonMediator.openThirdAppWithUrlS:schemeStr:",
          params: {
            "0": "weixin://",
            "1": "1142110895"
          }
        };
      } else if (system == "android") {
        param = {
          jsonType: "1",
          jumpJson: {
            page: "openApp",
            url: "weixin://",
            appPackageName: "com.tencent.mm"
          }
        };
      }
      Nb.NativeBridge("openPage", param, 0);
      break;
  }
}

/**
 * 站外打开fit
 * 0 : 打开app内网页
 */
function openFit(page: string, obj: any) {
  let param: object = {};
  if (system == "ios") {
    param = {
      page: "CommonMediator.pushToWebView:",
      params: {
        "0": obj.url
      }
    };
    const paramObj: string = encodeURI(JSON.stringify(param));
    window.location.href = "https://www.urfit.cn/openPage?id=" + paramObj;
  } else {
    param = {
      page: "com.sportq.fit.business.mine.activity.Mine03WebUrlActivity",
      webUrl: obj.url
    };
    const paramObj: string = encodeURI(JSON.stringify(param));
    window.location.href =
      "https://gio.ren/dQeXOxB?jsonType=1&jumpJson=" + paramObj;
  }
}

export { NativeOpenPage, openFit };
