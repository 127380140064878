import { render, staticRenderFns } from "./2021-4-22.vue?vue&type=template&id=736e4a71&scoped=true"
import script from "./2021-4-22.vue?vue&type=script&lang=ts"
export * from "./2021-4-22.vue?vue&type=script&lang=ts"
import style0 from "./2021-4-22.vue?vue&type=style&index=0&id=736e4a71&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "736e4a71",
  null
  
)

export default component.exports