/*
 * @Description:
 * @Autor: liuguangkuo
 * @Date: 2020-03-04 17:24:23
 * @LastEditTime: 2020-03-10 16:22:36
 */

const wx = require("weixin-js-sdk");
import axios from "@/tool/axios";

interface wechatShareType {
  title: string;
  desc: string;
  link: string;
  img: string;
}

export default {
  methods: {
    wechatShare(info: wechatShareType) {
      axios
        .get(
          "/wuAPI/fitDcApi/fitH5Act2020/getWeiXinInfoReq?form.reqUrl=" +
            window.location.href.split("#")[0]
        )
        .then(res => {
          wx.config({
            debug: false,
            appId: res.data.entRet.appId, // 必填，公众号的唯一标识
            timestamp: res.data.entRet.timestamp, // 必填，生成签名的时间戳
            nonceStr: res.data.entRet.nonceStr, // 必填，生成签名的随机串
            signature: res.data.entRet.signature, // 必填，签名，见附录1
            jsApiList: ["onMenuShareTimeline", "onMenuShareAppMessage"] // 必填，需要使用的 JS 接口列表，所有JS接口列表见附录2
          });
        });

      wx.ready(() => {
        const share_title = info.title;
        const share_desc = info.desc;
        const share_link = info.link;
        const share_img = info.img;
        wx.showOptionMenu();
        wx.onMenuShareTimeline({
          title: share_title, // 分享标题
          link: share_link, // 分享链接
          imgUrl: share_img // 分享图标
        });
        wx.onMenuShareAppMessage({
          title: share_title, // 分享标题
          desc: share_desc, // 分享描述
          link: share_link, // 分享链接
          imgUrl: share_img // 分享图标
        });
      });
    }
  }
};
