
import Vue from "vue";
import { Col, Row, Toast } from "vant";
Vue.use(Col)
  .use(Row)
  .use(Toast);
export default Vue.extend({
  data() {
    return {
      info: {
        entUser: {},
        lstForder: []
      } //页面数据
    };
  },
  created() {
    const _this = this;
    _this.$axios
      .get("/wuAPI/fitDcApi/fitH5Act/getHxCustSerUInfoResource", {
        params: {
          "form.reqUid": "11888",
          "form.channel": "dc3",
          "form.userId": _this.$route.query.userid
        }
      })
      .then(res => {
        _this.info = res.data.entRet;
      })
      .catch(err => {
        Toast("页面加载失败");
      });
  },
  methods: {}
});
