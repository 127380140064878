
import Vue from "vue";
import { Overlay, Icon, Toast } from "vant";
import wxShare from "../../tool/wechat";
Vue.use(Overlay)
  .use(Icon)
  .use(Toast);
export default Vue.extend({
  mixins: [wxShare],
  data() {
    return {
      show: false, //教练弹窗显示状态
      info: [], //页面数据
      liveList: [], //直播列表
      nowIndex: 0, //点击第几个教练
      nowTab: 0, //选中哪一天
      browser: "fit", //浏览器
      systemTipsFlg: false, //浏览器
      notificationFlg: false, //通知弹窗
      notificationType: 0, //是否开启通知
      notificationIndex: 0, //通知后置index,
      lvid: "" //当前点击课程
    };
  },
  created() {
    const _this = this;
    _this.browser = _this.$B.browser();
    _this.$axios
      .get("/wuAPI/fitDcApi/fitH5Act2020/getLiveActReq", {
        params: {
          "form.reqUid": "64789-vue",
          "form.channel": _this.browser == "fit" ? "dc2" : "wxShare",
          "form.userId": this.$F.userid()
        }
      })
      .then(res => {
        _this.info = res.data.entRet;
        (_this.info as any).lstDayDs.map(function(item: any, index: number) {
          if (item.todayFlg == 1 || item.todayFlg == 2) {
            _this.liveList = item.lstLiveD;
            _this.nowIndex = 0;
            _this.nowTab = index;
          }
        });
      })
      .catch(err => {
        Toast("页面加载失败");
      });

    /**
     * 设置微信分享
     */
    if (_this.browser == "wechat") {
      _this.setShare();
    }

    /**
     * 右上角分享初始化
     */
    _this.$bridge.setShareInfo({
      title: "Fit TV直播课",
      link: "https://vue.fitapp.cn/subject/live",
      imgUrl: "https://media.fitapp.cn/fit/fitLive/share.jpg",
      desc: "系列好课播不停！点击加入",
      ctitle: "Fit TV直播课",
      weiboTitle: "Fit TV直播课@FitApp 系列好课播不停！点击加入@FitApp",
      weiboImg: "https://media.fitapp.cn/fit/fitLive/weiboShare.jpg",
      olapflg: "25",
      display: "1"
    });
    /**
     * 页面加载设置
     */
    _this.$bridge.setBridgeIsShowInfo({
      close: "1",
      pull: "1",
      prev: "1",
      noback: "0",
      slip: "1",
      service: "0"
    });
  },
  methods: {
    /**
     * 查看日期
     */
    tabFn(index: number) {
      this.nowTab = index;
      let lstLiveD = (this.info as any).lstDayDs[index].lstLiveD;
      if (lstLiveD.length > 0) {
        this.liveList = lstLiveD;
      } else {
        this.liveList = [];
      }
    },
    /**
     * 查看教练详情
     */
    showTips(index: number) {
      this.nowIndex = index;
      this.show = true;
    },
    openPage(link, lvid, title, coachNm, targetModule) {
      const _this = this;
      if (_this.browser == "wechat") {
        _this.systemTipsFlg = true;
        _this.gioFn(1, coachNm, targetModule, title, lvid);
        return false;
      }

      // _this.gioFn(2, coachNm, targetModule, title, lvid);
      if (_this.$F.dev() >= 634) {
        _this.$openPage.NativeOpenPage("2", link);
      } else {
        if (_this.$B.system() == "ios") {
          _this.$bridge.NativeBridge(
            "openSafari",
            "https://www.fitapp.cn/h5/douyinB"
          );
        } else {
          window.location.href = "https://www.fitapp.cn/h5/douyinB";
        }
      }
    },
    /**
     * gio统计
     * type 渠道 1: wechat,2:app
     * coach_name	教练名称
     * web_channel	网页渠道
     * web_uid	网页用户id
     * target_module	目标模块
     * target_title	目标标题
     * target_id	目标id
     */
    gioFn(type, coach_name, target_module, target_title, target_id) {
      const _this = this;
      if (type == 1) {
        (window as any).gio("track", "web_live_plus_click", {
          web_channel: "wechat",
          web_uid: 0,
          coach_name: coach_name,
          target_module: target_module,
          target_title: target_title,
          target_id: target_id
        });
      } else {
        (window as any).gio("track", "web_live_plus_click", {
          web_channel: "app",
          web_uid: _this.$F.userid(),
          coach_name: coach_name,
          target_module: target_module,
          target_title: target_title,
          target_id: target_id
        });
      }
    },
    /**
     * 微信分享
     */
    setShare() {
      const shareInfo = {
        title: `Fit TV直播课`,
        desc: `系列好课播不停！点击加入`,
        link: "https://vue.fitapp.cn/subject/live",
        img: "https://media.fitapp.cn/fit/fitLive/share.jpg"
      };
      (this as any).wechatShare(shareInfo);
    },
    /**
     * 预约
     */
    reserveFn(index, lvid, title, coachNm, targetModule) {
      let _this = this;
      _this.lvid = lvid;
      _this.gioFn(2, coachNm, targetModule, title, lvid);
      _this.$bridge.getBridgeSetting({ name: "notification" });
      _this.$bridge.sendWebMessage(function(type) {
        window.console.log(type, 12312312);
        if (_this.$B.system() == "ios") {
          _this.notificationType = JSON.parse(type).type;
        } else {
          _this.notificationType = type;
        }
        if (_this.notificationType == 0) {
          _this.notificationFlg = true;
        }
        _this.notificationIndex = index;
        _this.$axios
          .post(
            "/wuAPI/fitDcApi/fitPush/procSpecialPushReq.do",
            _this.$qs.stringify({
              "form.reqUid": "64789-vue",
              "form.channel": "dc2",
              "form.userId": _this.$F.userid(),
              "form.targetId": lvid,
              "form.genFlg": "fitQiniuLiveYuyueAct"
            })
          )
          .then(res => {
            if (_this.notificationType == 1) {
              Toast({
                message: "预约成功，开始前15分钟会有<br />消息通知您",
                type: "html"
              });
            }
            (_this.info as any).lstDayDs[_this.nowTab].lstLiveD[
              index
            ].startFlg = 3;
            if (_this.$F.dev() >= 643) {
              _this.$bridge.NativeBridge("liveSubscribeSuccess", { id: lvid });
            }
          })
          .catch(err => {
            Toast("页面加载失败");
          });
      });
    },
    /**
     * 发送给客户端
     */
    // liveSubscribeSuccess(lvid){
    //     window.console.log(this.$bridge.NativeBridge,31312)
    //     this.$bridge.NativeBridge("liveSubscribeSuccess",{ "id": lvid });
    // },
    /**
     * 预约请求
     */
    /**
     * 微信打开活动页面openFit
     */
    goFit() {
      this.$openPage.openFit("1", {
        url: "https://vue.fitapp.cn/subject/live"
      });
    },
    /**
     * 打开通知
     */
    openSetting() {
      this.$openPage.NativeOpenPage("4");
      this.notificationFlg = false;
      (this.info as any).lstDayDs[this.nowTab].lstLiveD[
        this.notificationIndex
      ].startFlg = 3;
    },
    /**
     * 关闭通知弹出
     */
    closeNotificationFn() {
      let _this = this;
      _this.notificationFlg = false;
      setTimeout(function() {
        (_this.info as any).lstDayDs[_this.nowTab].lstLiveD[
          _this.notificationIndex
        ].startFlg = 3;
        Toast({
          message: "预约成功",
          type: "html"
        });
      }, 500);
    },
    /**
     * 微信通知
     */
    wechatMessage() {
      const _this = this;
      const scene = "1000";
      const obj = {
        appid: "",
        scene: scene,
        template_id: "8JVFd2jeUB2gmQqfTfPoftnaSTSCNiBZNf4IKoV0rZw",
        reserved: new Date().getTime()
      };
      this.$bridge.NativeBridge("wechatPushAuth", obj);
      /**接受客户端数据 */
      (window as any).sendWebMessage = function(val) {
        let obj = JSON.parse(val);
        window.console.log(obj, 312312);
        if (obj.name == "wechatPushAuth") {
          if (obj.json.action == "confirm") {
            _this.wechatPush(obj.json.openId, obj.json.templateId, scene);
            Toast({
              message: "预约成功，开始前15分钟会有<br />消息通知您",
              type: "html"
            });
          } else {
            _this.notificationFlg = false;
            Toast({
              message: "预约成功",
              type: "html"
            });
          }
        }
      };
    },
    /**
     * 微信推送
     */
    wechatPush(openid, template_id, scene) {
      const _this = this;
      _this.$axios
        .post(
          "/wuAPI/fitDcApi/fitH5Act/getWxLiveYuyueResource",
          _this.$qs.stringify({
            "form.reqUid": "64789-vue",
            "form.channel": "dc2",
            "form.userId": _this.$F.userid(),
            "form.lvId": _this.lvid,
            "form.openid": openid,
            "form.tempId": template_id,
            "form.scene": scene
          })
        )
        .then(res => {
          _this.notificationFlg = false;
        })
        .catch(err => {
          Toast("页面加载失败");
        });
    }
  }
});
