/*
 * @Description:
 * @Autor: liuguangkuo
 * @Date: 2020-03-20 16:16:29
 * @LastEditTime: 2021-03-10 16:46:06
 */
/**
 * 减脂营活动路由
 */
let routes = [
  /**
   * 减脂营首页
   */
  {
    path: "/fat/index",
    name: "fat/index",
    component: require("@/views/fat/index").default,
    meta: {
      title: "超级减脂营",
      auth: true
    }
  },
  /**
   * 常见问题
   */
  {
    path: "/fat/qa",
    name: "fat/qa",
    component: require("@/views/fat/qa").default,
    meta: {
      title: "常见问题"
    }
  },
  /**
   * 确认订单
   */
  {
    path: "/fat/creatOrder",
    name: "fat/creatOrder",
    component: require("@/views/fat/creatOrder").default,
    meta: {
      title: "确认订单",
      auth: true
    }
  },
  /**
   * 报名成功
   */
  {
    path: "/fat/orderDetails",
    name: "fat/orderDetails",
    component: require("@/views/fat/orderDetails").default,
    meta: {
      title: "报名成功",
      auth: true
    }
  },
  /**
   * 步骤
   */
  {
    path: "/fat/step",
    name: "fat/step",
    component: require("@/views/fat/step").default,
    meta: {
      title: "开营倒计时",
      auth: true
    }
  }
];
export default routes;
