
import Vue from "vue";
import { Toast, Swipe, SwipeItem, Lazyload, Icon, Popup } from "vant";
import wxShare from "../../tool/wechat";
Vue.use(Toast)
  .use(Swipe)
  .use(SwipeItem)
  .use(Lazyload)
  .use(Icon)
  .use(Popup);
export default Vue.extend({
  mixins: [wxShare],
  data() {
    return {
      info: {
        lstVideo: [
          {
            imgUrl: ""
          }
        ],
        courseName: "",
        courseType: "1",
        courseIntro: ""
      },
      isPause: false,
      current: 0,
      videoPosterShow: true
    };
  },
  created() {
    this.getPageInfo();
    /**
     * 设置微信分享
     */
    if (this.$B.browser() == "wechat") {
      this.setShare();
    }
  },
  methods: {
    //获取页面数据
    getPageInfo() {
      let _this = this;
      _this.$axios
        .post(
          "/zhangAPI/SuperCoachWeb/scmakeorder/getCourseSysDet",
          _this.$qs.stringify({
            "form.userId": "10092",
            "form.courseId": _this.$route.query.courseId
          })
        )
        .then(res => {
          _this.info = res.data.entRet;
          window.document.title = res.data.entRet.courseName;
        })
        .catch(res => {
          Toast("页面加载失败");
        });
    },
    onChange(index) {
      this.current = index;
      const video: any = document.getElementById("video");
      video.pause();
      if (!this.isPause) {
        video.currentTime = 0;
      }
    },
    /**
     * 播放视频
     */
    playVideo() {
      const _this = this;
      const video: any = document.getElementById("video");
      video.play();
      _this.videoPosterShow = false;
      _this.isPause = false;
      video.addEventListener("pause", function() {
        _this.videoPosterShow = true;
        _this.isPause = true;
      });
    },
    /**
     * 微信分享
     */
    setShare() {
      const shareInfo = {
        title: this.info.courseName,
        desc: this.info.courseIntro,
        link: window.location.href,
        img: this.info.lstVideo[0].imgUrl
      };
      (this as any).wechatShare(shareInfo);
    }
  }
});
