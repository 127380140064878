/*
 * @Description:
 * @Autor: liuguangkuo
 * @Date: 2020-03-20 16:16:29
 * @LastEditTime: 2021-12-23 17:41:11
 */
/**
 * 超级私教教练端
 */
let routes = [
  /**
   *训练计划管理
   */
  {
    path: "/cjsj/planSearch",
    name: "cjsj/planSearch",
    component: require("@/views/cjsj/planSearch").default,
    meta: {
      title: "训练计划管理",
      auth: true
    }
  },
  {
    path: "/cjsj/userPlan",
    name: "cjsj/userPlan",
    component: require("@/views/cjsj/userPlan").default,
    meta: {
      title: "编辑计划日程",
      auth: true
    }
  },
  {
    path: "/cjsj/courseTime",
    name: "cjsj/courseTime",
    component: require("@/views/cjsj/courseTime").default,
    meta: {
      title: "用户课时数",
      auth: true
    }
  },
  {
    path: "/cjsj/planNum",
    name: "cjsj/planNum",
    component: require("@/views/cjsj/planNum").default,
    meta: {
      title: "常规课",
      auth: true
    }
  },
  {
    path: "/cjsj/editPlan",
    name: "cjsj/editPlan",
    component: require("@/views/cjsj/editPlan").default,
    meta: {
      title: "心肺燃脂",
      auth: true
    }
  },
  {
    path: "/cjsj/createPlan",
    name: "cjsj/createPlan",
    component: require("@/views/cjsj/createPlan").default,
    meta: {
      title: "心肺燃脂",
      auth: true
    }
  },
  //用户协议
  {
    path: "/cjsj/agreement",
    name: "cjsjAgreement",
    component: require("@/views/cjsj/agreement").default,
    meta: {
      title: "用户协议"
    }
  },
  //隐私条款
  {
    path: "/cjsj/privacy",
    name: "cjsjPrivacy",
    component: require("@/views/cjsj/privacy").default,
    meta: {
      title: "隐私条款"
    }
  },
  //1对3活动
  {
    path: "/cjsj/league",
    name: "cjsjLeague",
    component: require("@/views/cjsj/league").default,
    meta: {
      title: "超级私教1对3私教课"
    }
  },
  {
    path: "/cjsj/leagueSuccess",
    name: "leagueSuccess",
    component: require("@/views/cjsj/leagueSuccess").default,
    meta: {
      title: "添加助教"
    }
  }
];
export default routes;
