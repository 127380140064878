import { render, staticRenderFns } from "./2021-6-18.vue?vue&type=template&id=232dccc2&scoped=true"
import script from "./2021-6-18.vue?vue&type=script&lang=ts"
export * from "./2021-6-18.vue?vue&type=script&lang=ts"
import style0 from "./2021-6-18.vue?vue&type=style&index=0&id=232dccc2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "232dccc2",
  null
  
)

export default component.exports